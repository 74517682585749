import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pricing-structure',
  templateUrl: './pricing-structure.component.html',
  styleUrls: ['./pricing-structure.component.css']
})
export class PricingStructureComponent implements OnInit,OnChanges {
  usertype:string=this.navigatorLookupService.getUserType();
  setUpFees:string[]=this.navigatorLookupService.setUpFees;
  pricingStructureFormGroup: FormGroup;
  zmbizi = false;
  @Input() inputData:any={};
  @Input() merchantName='';
  @Output() outPutData = new EventEmitter<any>();
  @Input() isClicked: boolean;
  @Input() dinersClub:boolean;
  @Input() product:string;

  constructor(private formBuilder: FormBuilder,private navigatorLookupService: NavigatorLookupService, private window: Window) { }

  ngOnInit() {
    this.pricingStructureFormGroup = this.formBuilder.group({
      acceptPrice:[false],
      consent1:[false,Validators.requiredTrue],
      consent2:[false],
      dinersConsent:[false,Validators.requiredTrue],
      googleAddConsent:[false],
      consent5:[false,Validators.requiredTrue],
      consent6:[false,Validators.requiredTrue],
      consent7:[false,Validators.requiredTrue],
      consent8:[false],
      consent9:[false],
      consent10:[false],
      consent11:[false,Validators.requiredTrue],
      setUpFee:[],
      domesticDebitCardRate:[, Validators.required],
      domesticCreditCardRate:[, Validators.required],
      internationalCardRate:[, Validators.required],
      dinersCardRate:[]
    });
    this.pricingStructureFormGroup.patchValue(this.inputData);
    this.setDinersConsentValidator();
    this.pricingStructureFormGroup.valueChanges.subscribe(selectedValue  => {
      this.outPutData.emit(JSON.stringify(this.pricingStructureFormGroup.value));
    });
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
      this.pricingStructureFormGroup.get("domesticDebitCardRate").setValue("2.5%");
      this.pricingStructureFormGroup.get("domesticCreditCardRate").setValue("3%");
      this.pricingStructureFormGroup.get("internationalCardRate").setValue("3%");
      this.merchantName = '';
    }else{
      this.pricingStructureFormGroup.get("consent2").setValidators([Validators.requiredTrue])
      this.pricingStructureFormGroup.get("consent2").updateValueAndValidity()
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.pricingStructureFormGroup){
      if(changes['dinersClub']){
        this.setDinersConsentValidator();
      }
      this.pricingStructureFormGroup.patchValue(this.inputData);
    }
    if(this.product === 'SIMPLY_BLU'){
      this.setSimplyBluContentValidator();
    }

  }

  setSimplyBluContentValidator(){
    this.pricingStructureFormGroup.get('domesticDebitCardRate').setValue('3.0% (excl. VAT)');
    this.pricingStructureFormGroup.get('internationalCardRate').setValue('3.50% (excl. VAT)');
    this.pricingStructureFormGroup.get('setUpFee').setValue('Free*');
    this.pricingStructureFormGroup.get('dinersCardRate').setValue('3.0%');

    this.pricingStructureFormGroup.get('domesticCreditCardRate').clearValidators();
    this.pricingStructureFormGroup.get('domesticCreditCardRate').updateValueAndValidity();
  }

  setDinersConsentValidator(){
    if(this.dinersClub){
      this.pricingStructureFormGroup.get('dinersConsent').setValidators([Validators.requiredTrue]);
      this.pricingStructureFormGroup.get('dinersConsent').updateValueAndValidity();
    }
    else{
      this.pricingStructureFormGroup.get('dinersConsent').clearValidators();
      this.pricingStructureFormGroup.get('dinersConsent').updateValueAndValidity();
    }
  }
}
