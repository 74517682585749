import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { HTTPStatus } from 'src/app/services/http-status/http-status.service';
import { Router } from '@angular/router';
import { DevicePickerDialogComponent } from '../navigator/device-picker-dialog/device-picker-dialog.component';
import { NavigatorLookupService } from '../navigator/navigator-lookup.service';
import {ExistingCustomerComponent} from '../navigator/existing-customer/existing-customer.component';
import {ProductInfoComponent} from '../navigator/product-info/product-info.component';
import {BusinessInfoComponent} from '../navigator/business-info/business-info.component';
import {BusinessLocationComponent} from '../navigator/business-location/business-location.component';
import {BusinessOwnersComponent} from '../navigator/business-owners/business-owners.component';
import {BankingDetailsComponent} from '../navigator/banking-details/banking-details.component';
import {PricingStructureComponent} from '../navigator/pricing-structure/pricing-structure.component';
import {ConfirmationDialogComponent} from '../navigator/confirmation-dialog/confirmation-dialog.component';
import { SalesConfirmationComponent } from '../navigator/sales-confirmation/sales-confirmation.component';
import {NavigatorNotification} from '../navigator/navigator-notification';

@Component({
  selector: 'app-zmbizi',
  templateUrl: './zmbizi.component.html',
  styleUrls: ['./zmbizi.component.css']
})
export class ZmbiziComponent implements OnInit {

  usertype:string=this.navigatorLookupService.getUserType();
  product='';
  HTTPActivity: boolean;
  numOwners;
  businessTypePass;
  step = 0;
  pricingPageClicked = false;
  productPageClicked=false;
  existingCustomerData = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : {};
  existingCustomer = this.existingCustomerData.existingCustomer;
  referenceNumber='';
  productData = localStorage.getItem('product') ? JSON.parse(localStorage.getItem('product')) : {};
  businessInfoData: any = localStorage.getItem('businessInfo') ? JSON.parse(localStorage.getItem('businessInfo')) : {};
  businessLocationData: any = localStorage.getItem('businessLocation') ? JSON.parse(localStorage.getItem('businessLocation')) : {};
  merchantEmail=this.businessLocationData?this.businessLocationData.businessEmailAddress:'';
  businessName = this.businessInfoData ? this.businessInfoData.businessTradingName : '';
  dinersClub: boolean = this.productData ? this.productData.dinersclub && !this.productData.dinersClubType : false;
  businessOwnerData: any = localStorage.getItem('businessOwner') ? JSON.parse(localStorage.getItem('businessOwner')) : {};
  bankingData: any = localStorage.getItem('banking') ? JSON.parse(localStorage.getItem('banking')) : {};
  pricingData: any = localStorage.getItem('pricing') ? JSON.parse(localStorage.getItem('pricing')) : {};
  merchantName: string = this.businessOwnerData.businessOwners ? this.businessOwnerData.businessOwners.length > 0 ? this.businessOwnerData.businessOwners[0].ownerName : '' : '';
  zmbizi = true;
  // @ts-ignore
  @ViewChild('stepper') stepper;
  // @ts-ignore
  @ViewChild(ExistingCustomerComponent) existingCustomerInfo: ExistingCustomerComponent;
  // @ts-ignore
  @ViewChild(ProductInfoComponent) productInfo: ProductInfoComponent;
  // @ts-ignore
  @ViewChild(BusinessInfoComponent) businessInfo: BusinessInfoComponent;
  // @ts-ignore
  @ViewChild(BusinessLocationComponent) businessLocation: BusinessLocationComponent;
  // @ts-ignore
  @ViewChild(BusinessOwnersComponent) businessOwner: BusinessOwnersComponent;
  // @ts-ignore
  @ViewChild(BankingDetailsComponent) bankingDetails: BankingDetailsComponent;
  // @ts-ignore
  @ViewChild(PricingStructureComponent) pricingDetails: PricingStructureComponent;
  // @ts-ignore
  @ViewChild(SalesConfirmationComponent) salesConfirmation: SalesConfirmationComponent;
  get existingCustomerComponent() {
    return this.existingCustomerInfo ? this.existingCustomerInfo.existingCustomerFormGroup : null;
  }
  get productInfoComponent() {
    return this.productInfo ? this.productInfo.productInfoFormGroup : null;
  }
  get businessInfoComponent() {
    return this.businessInfo ? this.businessInfo.businessInfoFormGroup : null;
  }
  get businessLocationComponent() {
    return this.businessLocation ? this.businessLocation.businessLocationFormGroup : null;
  }
  get businessOwnerComponent() {
    return this.businessOwner ? this.businessOwner.businessOwnersFormGroup : null;
  }
  get bankingDetailsComponent() {
    return this.bankingDetails ? this.bankingDetails.bankingDetailsFormGroup : null;
  }

  get pricingComponent() {
    return this.pricingDetails ? this.pricingDetails.pricingStructureFormGroup : null;
  }
  get salesConfirmationComponent() {
    return this.salesConfirmation ? this.salesConfirmation.salesConfirmationFormGroup : null;
  }
  seventhFormGroup: FormGroup;
  eightthFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder, private confirmationDialog: MatDialog, private httpStatus: HTTPStatus, private navigatorLookupService: NavigatorLookupService,private router: Router,private deviceDialog: MatDialog, private window: Window) {
    switch (this.router.url) {
      case '/autoLink':
        this.product='AUTO_LINK';
        break;
      case '/pocketBiz':
        this.product='POCKET_BIZ';
        break;
      case '/masterPass':
        this.product='MASTER_PASS';
        break;
      case '/simplyBlu':
        this.product='SIMPLY_BLU';
        break;
      case '/ecommerce':
        this.product='ECOMMERCE';
        break;
      case '/vas':
        this.product='VAS';
        break;
      case '/recurringPayments':
        this.product='RECURRING_PAYMENTS';
        break;
      case '/electronicBill':
        this.product='ELECTRONIC_BILL';
        break;
      default:
        this.product='';
    }
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.HTTPActivity = status;
    });
  }
  ngOnInit() {
    if (history.state.data) {
      localStorage.removeItem('customer');
      localStorage.removeItem('businessLocation');
      localStorage.removeItem('product');
      localStorage.removeItem('banking');
      localStorage.removeItem('pricing');
      localStorage.removeItem('termsAndConditions');
      localStorage.removeItem('businessInfo');
      localStorage.removeItem('businessOwner');
    }
    this.seventhFormGroup = this.formBuilder.group({
      sevenCtrl: ['']
    });
    this.eightthFormGroup = this.formBuilder.group({
      eightCtrl: ['']
    });
  }
  setNumberOfOwners(numberOwner: number) {
    this.numOwners = numberOwner;
  }

  setBusinessType(businessType: string) {
    this.businessTypePass = businessType;
  }

  addProductInfo(data: any) {
    localStorage.setItem('product', data);
    if (data) {
      const product = JSON.parse(data);
      this.dinersClub = product.dinersclub && !product.dinersClubType;
      this.product = product.productName;
    }
    this.productData = localStorage.getItem('product') ? JSON.parse(localStorage.getItem('product')) : {};
  }
  addBusinessInfo(data: any) {
    localStorage.setItem('businessInfo', data);
    if (data) {
      const businessInfo = JSON.parse(data);
      // clear array before copying data to avoid ensuring the right number of elements are copied
      businessInfo.shares.length = 0;

      // Copy the value for shares
      // since an update to the form field was delayed
      // and the information was not being added to localStorage
      for(let i = 0; i < this.businessInfoComponent.controls['shares'].value.length; i++){
        businessInfo.shares.push(this.businessInfoComponent.controls['shares'].value[i]);
      }
      localStorage.setItem('businessInfo', JSON.stringify(businessInfo));
      this.businessName = businessInfo.businessTradingName;
    }
    this.businessInfoData = localStorage.getItem('businessInfo') ? JSON.parse(localStorage.getItem('businessInfo')) : {};
  }
  addBusinessLocation(data: any) {
    localStorage.setItem('businessLocation', data);
    if(data){
      const businessLocation=JSON.parse(data);
      this.merchantEmail=businessLocation.businessEmailAddress;
    }
    this.businessLocationData = localStorage.getItem('businessLocation') ? JSON.parse(localStorage.getItem('businessLocation')) : {};
  }
  createReferenceNumber(){
    if(sessionStorage.getItem('referenceNumber')){
         return true;
    }
    if(this.businessLocationComponent.invalid){
      return true;
    }
    else{
      const createRequest = {
        customeremail: this.businessLocationComponent.get('businessEmailAddress').value
         };
     this.navigatorLookupService.createDraft(createRequest).subscribe(data => {
        this.referenceNumber=data.body.referenceNumber;
        sessionStorage.setItem('referenceNumber', this.referenceNumber);
        sessionStorage.setItem('tableId', data.body.tableId);
        sessionStorage.setItem('tableIdItemId', data.body.tableIdItemId);
        sessionStorage.setItem('issueId', data.body.issueId);
      }, error => {
        this.stepperHandler(3);
        this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Technical Error', 'OK', 5000));
      });
    }

    }
  addBusinessOwner(data: any) {
    localStorage.setItem('businessOwner', data);
    if (data) {
      const owners = JSON.parse(data);
      this.merchantName = owners.businessOwners.length > 0 ? owners.businessOwners[0].ownerName : '';
    }
    this.businessOwnerData = localStorage.getItem('businessOwner') ? JSON.parse(localStorage.getItem('businessOwner')) : {};
  }
  addPricing() {
    this.pricingPageClicked = true;
    if (this.pricingComponent.invalid) {
      return false;
    }
  }
  addProductPage() {
    this.productPageClicked = true;
    if (this.productInfoComponent.invalid) {
      return false;
    }
  }
  addBanking(data: any) {
    localStorage.setItem('banking', data);
    this.bankingData = localStorage.getItem('banking') ? JSON.parse(localStorage.getItem('banking')) : {};
  }

  addPricingStructure(data: any) {
    localStorage.setItem('pricing', data);
    this.pricingData = localStorage.getItem('pricing') ? JSON.parse(localStorage.getItem('pricing')) : {};
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    switch (this.step) {
      case 0:
        if (this.productInfoComponent.invalid) {
          return false;
        }
        break;
      case 1:
        if (this.businessInfoComponent.invalid) {
          return false;
        }
        break;
      case 2:
        if (this.businessLocationComponent.invalid) {
          return false;
        }
        break;
      case 3:
        if (this.businessOwnerComponent.invalid) {
          return false;
        }
        break;
      case 4:
        if (this.bankingDetailsComponent.invalid) {
          return false;
        }
        break;
      case 5:
        if (this.pricingComponent.invalid) {
          return false;
        }
        break;
        case 6:
          this.step++;
      default:
        return false;
    }
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  submitForm() {
    if (this.productInfoComponent.invalid) {
      this.step = 0;
      return false;
    } else if (this.businessInfoComponent.invalid) {
      this.step = 1;
      return false;
    } else if (this.businessLocationComponent.invalid) {
      this.step = 2;
      return false;
    } else if (this.businessOwnerComponent&&this.businessOwnerComponent.invalid&&this.product!=='VAS') {
      this.step = 3;
      return false;
    } else if (this.bankingDetailsComponent&&this.bankingDetailsComponent.invalid&&this.product!=='VAS') {
      this.step = 4;
      return false;
    } else if (this.pricingComponent.invalid) {
      this.step = 5;
      return false;
    }
    else if (this.salesConfirmationComponent&&this.salesConfirmationComponent.invalid) {
      this.step = 7;
      this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Please complete MC Code field', 'OK', 5000));
      return false;
    }
    else {
      this.submitDraft();
    }

  }
  openConfirmationDialog(referenceNumber) {
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      width: '580px', height: '420px',
      data: {refNum: referenceNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      const data:any={};
      data.status='delete'
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      location.reload();
      localStorage.clear();
      this.clearInfoForms();
    });
  }

  stepperHandler(index) {
    this.stepper.selectedIndex = index;
  }
  getProductRequest(): any {
    const product: any = {};
    product.productName =  "ZMBIZI"; // TODO: this.productInfoComponent.get('productName').value;
    product.billingCycle = "Daily";
    product.bankingTime = "Automatic PM";
    product.refunds = this.productInfoComponent.get('refunds').value;
    product.cellphoneNumbers = this.productInfoComponent.get('cellphoneNumbers').value;
    return product;
  }
  getBusinessInfoRequest(): any {
    const businessInfo: any = {};
    businessInfo.businessType = this.productInfoComponent.get('productName').value==='VAS'?'SOLE_PROPRIETOR':this.businessInfoComponent.get('businessType').value;
    businessInfo.businessTradingName = this.businessInfoComponent.get('businessTradingName').value;
    businessInfo.annualTurnOver = this.productInfoComponent.get('productName').value==='VAS'?'0.0':this.businessInfoComponent.get('annualTurnOver').value;
    businessInfo.businessNature = this.businessInfoComponent.get('businessNature').value;
    businessInfo.merchantIndustry = this.businessInfoComponent.get('merchantIndustry').value;
    if (businessInfo.businessType !== 'SOLE_PROPRIETOR') {
      businessInfo.numberOfOwners = this.businessInfoComponent.get('numberOfOwners').value;
    }
    if (businessInfo.businessType !== 'SOLE_PROPRIETOR' && businessInfo.businessType !== 'PARTNERSHIP') {
      businessInfo.businessRegName = this.businessInfoComponent.get('businessRegName').value;
      businessInfo.businessRegNumber = this.businessInfoComponent.get('businessRegNumber').value;
      businessInfo.businessVATNumber = this.businessInfoComponent.get('businessVATNumber').value;
      businessInfo.numberOfShareholders = this.businessInfoComponent.get('numberOfShareholders').value;
      businessInfo.shareRequests = this.businessInfoComponent.get('shares').value;
    }
    return businessInfo;
  }

  getBusinessOwnersRequest():any[] {
    const businessOwners: any[] = [];
    for (let i = 0; i < this.businessOwnerComponent.get('businessOwners').value.length; i++) {
      const businessOwner: any = {};
      businessOwner.contactName = this.businessOwnerComponent.get('businessOwners').value[i].ownerName + " " + this.businessOwnerComponent.get('businessOwners').value[i].ownerSurname;
      businessOwner.emailAddress = this.businessOwnerComponent.get('businessOwners').value[i].emailAddress;
      businessOwner.idNumber = this.businessOwnerComponent.get('businessOwners').value[i].idType?this.businessOwnerComponent.get('businessOwners').value[i].passportNumber:this.businessOwnerComponent.get('businessOwners').value[i].ownerIdNumber;
      businessOwner.telNo = this.businessOwnerComponent.get('businessOwners').value[i].contactNumber;
      const fic: any = {};
      fic.publicOfficial = this.businessOwnerComponent.get('businessOwners').value[i].publicOfficialFic ? 'YES' : 'NO';
      fic.publicOfficialRel = this.businessOwnerComponent.get('businessOwners').value[i].relationshipFic ? 'YES' : 'NO';
      fic.debtReview = this.businessOwnerComponent.get('businessOwners').value[i].debtReview ? 'YES' : 'NO';
      fic.publicOfficialRelNature = this.businessOwnerComponent.get('businessOwners').value[i].natureOfRelationship === '' ? null : this.businessOwnerComponent.get('businessOwners').value[i].natureOfRelationship;
      fic.publicOfficialText = this.businessOwnerComponent.get('businessOwners').value[i].publicOfficialName;
      businessOwner.fic=fic;

      // If passport option is selected
      if(this.businessOwnerComponent.get('businessOwners').value[i].idType){
        businessOwner.ownerDOB = this.businessOwnerComponent.get('businessOwners').value[i].ownerDOB;
        businessOwner.passportExpiry = this.businessOwnerComponent.get('businessOwners').value[i].passportExpiry;
        businessOwner.permitType = this.businessOwnerComponent.get('businessOwners').value[i].permitType;
        businessOwner.permitNumber = this.businessOwnerComponent.get('businessOwners').value[i].permitNumber;
        businessOwner.permitIssue = this.businessOwnerComponent.get('businessOwners').value[i].permitIssue;
        businessOwner.permitExpiry = this.businessOwnerComponent.get('businessOwners').value[i].permitExpiry;
      }

      if (this.businessInfoComponent.get('businessType').value === 'SOLE_PROPRIETOR' || this.businessInfoComponent.get('businessType').value === 'PARTNERSHIP') {
        const address: any = {};
        address.buildingName= this.businessOwnerComponent.get('businessOwners').value[i].ownerBuildingName;
        address.city= this.businessOwnerComponent.get('businessOwners').value[i].ownerCity;
        address.postalCode= this.businessOwnerComponent.get('businessOwners').value[i].ownerPostalCode;
        address.province= this.businessOwnerComponent.get('businessOwners').value[i].ownerProvince;
        address.streetName= this.businessOwnerComponent.get('businessOwners').value[i].ownerStreet;
        address.suburb= this.businessOwnerComponent.get('businessOwners').value[i].ownerSuburb;
        businessOwner.address=address;
      }
      businessOwners.push(businessOwner);
    }
    return businessOwners;
  }

  clearExistingCustomerInfo(){
    this.existingCustomerInfo.existingCustomerFormGroup.reset(
      {
        referenceNumber:'',
        merchantEmailAddress:'',
        existingCustomer: false,
        salesConsultant:false
      }
    );
  }
  clearBusinessInfoForm(){
    this.businessInfo.businessInfoFormGroup.reset(
      {
        businessType: '',
        businessTradingName: '',
        businessRegName: '',
        businessRegNumber: '',
        businessVATNumber: '',
        merchantNumber:'',
        legalEntityName:'',
        businessNature: '',
        merchantIndustry: '',
        annualTurnOver: '',
        numberOfOwners: '',
        numberOfShareholders: '',
        numVASDevices:'',
        shares: new FormArray([]),
        devices:new FormArray([])
      }
    );
  }
  clearProductInfoForm(){
    this.productInfo.productInfoFormGroup.reset(
      {
        productName: 'ZMBIZI',
        productOption: '',
        choiceOfDomain: '',
        domainName: '',
        merchantWebsiteAddress: '',
        paymentGateway:'',
        simplyBluPaymentGateway:'',
        electronicBillPaymentGateway:'',
        recurringPaymentGateway:'',
        paymentMethod:'',
        typeOfDevice:'',
        portableDevice:[],
        counterTopDevice:[],
        portableDeviceModel:[],
        counterTopDeviceModel:[],
        numCounterTopDevice:[],
        numDevices:'',
        numPortableDevice:[],
        whenDevReq:[],
        dinersclub: false,
        americanExpress: false,
        rcs:[],
        prepaidSolutions: false,
        airTime:[],
        electricity:[],
        instantMoney:[],
        dinersClubType: false,
        dinersclubnum: '',
        americanexpressnum: '',
        rcsnum:'',
        fleet:[],
        garage:[],
        stannicFleet:[],
        wesBankFleet:[],
        absaFleet:[],
        nedFleet:[],
        stannicFleetNumber:[],
        wesBankFleetNumber:[],
        absaFleetNumber:[],
        nedFleetNumber:[],
        cashback:false,
        refunds:false,
        bankingTime:'3',
        billingCycle:'1',
        comments:''
      }
    );
  }
  clearBusinessLocationForm(){
    this.businessLocation.businessLocationFormGroup.reset({
      registeredAddressCheck: true,
      postalAddressCheck: true,
      //Physical Address
      buildingName: '',
      street: '',
      suburb: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',

      //Registered Address
      registeredBuildingName: '',
      registeredStreet: '',
      registeredSuburb: '',
      registeredCity: '',
      registeredProvince: '',
      registeredPostalCode: '',
      registeredCountry: '',

      //Postal Address
      postalBuildingName: '',
      postalStreet: '',
      postalSuburb: '',
      postalCity: '',
      postalProvince: '',
      postalPostalCode: '',
      postalCountry: '',
      postalPOBox:'',

      businessTelephoneNumber: '',
      businessEmailAddress: '',
      primaryContactPerson: '',
      primaryContactNumber: '',
      businessTradingHours:''
    });
  }
  clearBusinessOwnerForm(){
    this.businessOwner.businessOwnersFormGroup.reset({
      businessOwners: [
        {
          ownerName:  '',
          ownerSurname: '',
          sex: '',
          contactNumber: '',
          idType:false,
          ownerIdNumber:  '',
          passportNumber:'',
          passportExpiry:'',
          permitType:'',
          permitIssue: '',
          permitExpiry: '',
          permitNumber:'',
          ownerDOB: '',
          ownerCountry: '',
          maritalType: '',
          maritalStatus: '',
          emailAddress: '',
          ownerBuildingName: '',
          ownerStreet: '',
          ownerCity: '',
          ownerSuburb: '',
          ownerProvince: '',
          ownerPostalCode: '',
          publicOfficialFic: false,
          relationshipFic: false,
          debtReview: false,
          natureOfRelationship: '',
          publicOfficialName: ''
        }
      ]
    });
  }
  clearBankingDetailsForm(){
    this.bankingDetails.bankingDetailsFormGroup.reset(
      {
        bankName: '',
        branchName: '',
        branchCode: '',
        accountHolderName: '',
        accountNumber: ''
      }
    );
  }
  clearPricingInfoForm(){
    this.pricingDetails.pricingStructureFormGroup.reset(
      {
        acceptPrice:false,
        consent1:false,
        consent2:false,
        dinersConsent:false,
        googleAddConsent:false,
        consent5:false,
        consent6:false,
        consent7:false,
        consent8:false,
        consent9:false,
        consent10:false,
        consent11:false,
        setUpFee:[],
        domesticDebitCardRate:"2.5%",
        domesticCreditCardRate:"3%",
        internationalCardRate:"3%",
        dinersCardRate:[]
      }
    );
  }
  clearSalesConfirmationForm(){
    this.salesConfirmation.salesConfirmationFormGroup.reset({
      mcCode: '',
      consent1:false,
      consent2:false,
      consent3:false
    });
  }
  clearInfoForms() {
    this.clearExistingCustomerInfo();
    this.clearBusinessInfoForm();
    this.clearProductInfoForm();
    this.clearBusinessLocationForm();
    this.clearBusinessOwnerForm();
    this.clearBankingDetailsForm();
    this.clearPricingInfoForm();
    this.clearSalesConfirmationForm();
 }

  submitDraft() {
    const submitRequest = {
      bankRequest: this.bankingDetailsComponent?this.bankingDetailsComponent.value:null,
      businessAddressRequest: {
        address: {
          buildingName: this.businessLocationComponent.get('buildingName').value,
          city: this.businessLocationComponent.get('city').value,
          postalCode: this.businessLocationComponent.get('postalCode').value,
          province: this.businessLocationComponent.get('province').value,
          streetName: this.businessLocationComponent.get('street').value,
          suburb: this.businessLocationComponent.get('suburb').value
        },
        physicalAddressCheck: this.businessLocationComponent.get('registeredAddressCheck').value,   // check if registered address is the same as physical address
        businessEmailAddress: this.businessLocationComponent.get('businessEmailAddress').value,
        businessTelephoneNumber: this.businessLocationComponent.get('businessTelephoneNumber').value,
        primaryNumber: this.businessLocationComponent.get('primaryContactNumber').value,
        primaryPerson: this.businessLocationComponent.get('primaryContactPerson').value,
        businessTradingHours:this.businessLocationComponent.get('businessTradingHours').value
      },
      businessInfoRequest: this.getBusinessInfoRequest(),
      businessOwnerRequests: this.businessOwnerComponent?this.getBusinessOwnersRequest():null,
      productRequest: this.getProductRequest(),
      referenceNumber: sessionStorage.getItem('referenceNumber'),
      tableId:sessionStorage.getItem('tableId'),
      tableIdItemId:sessionStorage.getItem('tableIdItemId'),
      issueId:sessionStorage.getItem('issueId'),
      customeremail:this.businessLocationComponent.get('businessEmailAddress').value,
      pricing: {
        setupFee: "Free",
        subscriptionFee: "subscriptionFee",
        domesticDebitRate: this.pricingComponent.get("domesticDebitCardRate").value,
        domesticCreditRate: this.pricingComponent.get("domesticCreditCardRate").value,
        internationalCreditRate: this.pricingComponent.get("internationalCardRate").value,
        dinersFee: "N/A"
      },
      mccode: this.salesConfirmationComponent.get("mcCode").value
    };
    this.navigatorLookupService.submitDraft(submitRequest).subscribe(data => {
      sessionStorage.removeItem('referenceNumber');
      sessionStorage.removeItem('tableId');
      sessionStorage.removeItem('tableIdItemId');
      sessionStorage.removeItem('issueId');
      this.openConfirmationDialog(data.body.referenceNumber);
    }, error => {
      this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Technical Error', 'OK', 5000));
    });
  }
  openDeviceDialog() {
    const dialogRef = this.deviceDialog.open(DevicePickerDialogComponent, {
      width: '580px', height: '420px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
