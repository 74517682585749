import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
// import 'rxjs/add/observable/throw';
import { HTTPStatus } from './http-status.service';

@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(private status: HTTPStatus) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        this.status.setHttpStatus(true);
        return event;
      }),
      finalize(() => {
        this.status.setHttpStatus(false);
      })
    )
  }
}
