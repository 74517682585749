import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import {Observable} from 'rxjs';
export interface Bank {
  name: string;
  code: string;
}
export interface Branch {
  name: string;
  code: string;
  universalIndicator: string;
}
@Component({
  selector: 'app-banking-details',
  templateUrl: './banking-details.component.html',
  styleUrls: ['./banking-details.component.css']
})
export class BankingDetailsComponent implements OnInit,OnChanges {
  bankingDetailsFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder,private navigatorLookupService: NavigatorLookupService) { }
  @Input() inputData:any={};
  @Output() outPutData = new EventEmitter<any>();
  banks: Bank[]=[];
  branches: Branch[]=[];
  filteredBankOptions: Observable<any[]>;
  filteredBranchOptions: Observable<any[]>;
  formValue:any={};
  ngOnInit() {
    this.bankingDetailsFormGroup = this.formBuilder.group({
      bankName: ['', Validators.required],
      branchName: ['', Validators.required],
      branchCode: ['', [Validators.required,Validators.pattern('^[0-9]*$')]],
      accountHolderName: ['', Validators.required],
      accountNumber: ['', [Validators.required,Validators.pattern('^[0-9]*$')]]
    });
    this.bankingDetailsFormGroup.patchValue(this.inputData);
    this.navigatorLookupService.getBanks().subscribe(data => {this.banks=data.banks}, error => {});
    this.bankingDetailsFormGroup.valueChanges.subscribe(selectedValue  => {
      this.outPutData.emit(JSON.stringify(this.bankingDetailsFormGroup.value));
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.bankingDetailsFormGroup){
      this.bankingDetailsFormGroup.patchValue(this.inputData);
    }
  }
  setBranch(){
    this.navigatorLookupService.getBranches(this.bankingDetailsFormGroup.get('bankName').value,'').subscribe(data => {
      this.bankingDetailsFormGroup.patchValue({branchCode: data.branches[0].code,branchName:data.branches[0].name});
    }, error => {});
  }
}
