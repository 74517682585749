import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse, HttpErrorResponse, HttpInterceptor
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {DtmAnalyticsServiceLaunch} from '../dtm-analytics-service-launch'
import {catchError, tap} from 'rxjs/operators'
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private dtm: DtmAnalyticsServiceLaunch) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
//           this.dtm.logToConsole('RESPONSE CODE: ' + evt.status)
        }
      }),
      catchError((err: any) => {
        if(err instanceof HttpErrorResponse) {
//           this.dtm.logToConsole('RESPONSE CODE: ' + err.status)
          // log error
          this.dtm.sendSiteError(err.status, err.url, err.message)
          return throwError(err);
        }
        return of(err);
      }));




  }
}
