import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-business-owners',
  templateUrl: './business-owners.component.html',
  styleUrls: ['./business-owners.component.css']
})
export class BusinessOwnersComponent implements OnInit, OnChanges {
  businessOwnersFormGroup: FormGroup;
  @Input() numOwners: number;
  @Input() businessType: string;
  @Input() inputData:any={};
  @Output() outPutData = new EventEmitter<any>();
  relationships: any = this.navigatorLookupService.relationships;
  provinces: any = this.navigatorLookupService.provinces;
  permitTypes: any = this.navigatorLookupService.permitTypes;
  formData:any={};
  sexes: any = this.navigatorLookupService.sexes;
  maritalStatuses: string[] = this.navigatorLookupService.maritalStatuses;
  maritalTypes: string[] = this.navigatorLookupService.maritalTypes;
  countries: any = this.navigatorLookupService.countries;
  currentDate: Date;

  constructor(private formBuilder: FormBuilder, private navigatorLookupService: NavigatorLookupService) {
    this.currentDate = new Date();
  }

  ngOnInit() {
    this.businessOwnersFormGroup = this.formBuilder.group({
      businessOwners: new FormArray([])
    });
    this.formData = this.inputData;
    if (Object.keys(this.formData).length>0) {
      this.businessOwnersFormGroup.patchValue(this.formData);
      for(let i=0;i<this.formData.businessOwners.length;i++){
        this.businessOwners.push(this.formBuilder.group({
          ownerName:  [this.formData.businessOwners[i].ownerName?this.formData.businessOwners[i].ownerName:'',Validators.required],
          ownerSurname: [this.formData.businessOwners[i].ownerSurname?this.formData.businessOwners[i].ownerSurname:'',Validators.required],
          sex: [this.formData.businessOwners[i].sex?this.formData.businessOwners[i].sex:''],
          contactNumber: [this.formData.businessOwners[i].contactNumber?this.formData.businessOwners[i].contactNumber:'',[Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
          idType:[this.formData.businessOwners[i].idType?this.formData.businessOwners[i].idType:false],
          ownerIdNumber:  [this.formData.businessOwners[i].ownerIdNumber?this.formData.businessOwners[i].ownerIdNumber:''],
          passportNumber:  [this.formData.businessOwners[i].passportNumber?this.formData.businessOwners[i].passportNumber:''],
          passportExpiry: [this.formData.businessOwners[i].passportExpiry?this.formData.businessOwners[i].passportExpiry:''],
          permitNumber: [this.formData.businessOwners[i].permitNumber?this.formData.businessOwners[i].permitNumber:''],
          permitExpiry: [this.formData.businessOwners[i].permitExpiry?this.formData.businessOwners[i].permitExpiry:''],
          permitIssue: [this.formData.businessOwners[i].permitIssue?this.formData.businessOwners[i].permitIssue:''],
          permitType: [this.formData.businessOwners[i].permitType?this.formData.businessOwners[i].permitType:''],
          ownerDOB: [this.formData.businessOwners[i].ownerDOB?this.formData.businessOwners[i].ownerDOB:''],
          maritalStatus: [this.formData.businessOwners[i].maritalStatus?this.formData.businessOwners[i].maritalStatus:''],
          maritalType: [this.formData.businessOwners[i].maritalType?this.formData.businessOwners[i].maritalType:''],
          emailAddress: [this.formData.businessOwners[i].emailAddress?this.formData.businessOwners[i].emailAddress:'', [Validators.required, Validators.email]],
          ownerBuildingName: [this.formData.businessOwners[i].ownerBuildingName?this.formData.businessOwners[i].ownerBuildingName:''],
          ownerStreet: [this.formData.businessOwners[i].ownerStreet?this.formData.businessOwners[i].ownerStreet:''],
          ownerCity: [this.formData.businessOwners[i].ownerCity?this.formData.businessOwners[i].ownerCity:''],
          ownerSuburb: [this.formData.businessOwners[i].ownerSuburb?this.formData.businessOwners[i].ownerSuburb:''],
          ownerProvince: [this.formData.businessOwners[i].ownerProvince?this.formData.businessOwners[i].ownerProvince:''],
          ownerCountry: [this.formData.businessOwners[i].ownerCountry?this.formData.businessOwners[i].ownerCountry:''],
          ownerPostalCode: [this.formData.businessOwners[i].ownerPostalCode?this.formData.businessOwners[i].ownerPostalCode:''],
          publicOfficialFic: [this.formData.businessOwners[i].publicOfficialFic?this.formData.businessOwners[i].publicOfficialFic:false,Validators.required],
          relationshipFic: [this.formData.businessOwners[i].relationshipFic?this.formData.businessOwners[i].relationshipFic:false,Validators.required],
          debtReview:[this.formData.businessOwners[i].debtReview?this.formData.businessOwners[i].debtReview:false,Validators.required],
          natureOfRelationship: [this.formData.businessOwners[i].natureOfRelationship?this.formData.businessOwners[i].natureOfRelationship:''],
          publicOfficialName: [this.formData.businessOwners[i].publicOfficialName?this.formData.businessOwners[i].publicOfficialName:'']
        }));
      }
    }
    else{
      this.addOwner();
    }
    this.businessOwnersFormGroup.valueChanges.subscribe(selectedValue  => {
      this.outPutData.emit(JSON.stringify(this.businessOwnersFormGroup.value));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("marital status value" + this.businessOwners.get('maritalStatus').value + " Marital Type: " +this.businessOwners.get('maritalType').valid) ;
    if(this.businessOwnersFormGroup){
      if(changes['numOwners']){
        this.addOwner();
      }
      this.businessOwnersFormGroup.patchValue(this.inputData);
    }
  }

  get businessOwners() {
    return this.businessOwnersFormGroup.get('businessOwners') as FormArray;
  }

  onMaritalStatusChange(event, index){
    let businessOwner = this.businessOwners.controls[index] as FormGroup;
    if(event.value === "Married"){
      businessOwner.controls["maritalType"].setValidators(Validators.required);
      businessOwner.controls["maritalType"].updateValueAndValidity();
    }
    else{
      businessOwner.controls["maritalType"].clearValidators();
      businessOwner.controls["maritalType"].updateValueAndValidity();
    }
  }
  addOwner() {
    const numberOfOwners = this.numOwners;
    if (this.businessOwners.length < numberOfOwners) {
      for (let i = this.businessOwners.length; i < numberOfOwners; i++) {
        this.businessOwners.push(this.formBuilder.group({
          ownerName:  ['',Validators.required],
          ownerSurname: ['',Validators.required],
          sex: [''],
          contactNumber: ['',[Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
          idType:[false],
          ownerIdNumber:  [''],
          passportNumber:[''],
          passportExpiry:[''],
          permitType:[''],
          permitIssue: [''],
          permitExpiry: [''],
          permitNumber:[''],
          ownerDOB: [''],
          ownerCountry: [''],
          maritalType: [''],
          maritalStatus: [''],
          emailAddress: ['', [Validators.required, Validators.email]],
          ownerBuildingName: [''],
          ownerStreet: [''],
          ownerCity: [''],
          ownerSuburb: [''],
          ownerProvince: [''],
          ownerPostalCode: [''],
          publicOfficialFic: [false,Validators.required],
          relationshipFic: [false,Validators.required],
          debtReview: [false,Validators.required],
          natureOfRelationship: [''],
          publicOfficialName: ['']
        }));
      }
    } else {
      for (let i = this.businessOwners.length; i >= numberOfOwners; i--) {
        this.businessOwners.removeAt(i);
      }
    }
  }
}
