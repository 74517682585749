import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NavigatorLookupService} from '../navigator-lookup.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  sources: any = this.navigatorLookupService.sources;
  confirmationDialogForm: FormGroup;
  ref:string;
  zmbizi = false; 
  constructor(private formBuilder: FormBuilder,public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,private navigatorLookupService: NavigatorLookupService,@Inject(MAT_DIALOG_DATA) data, private window: Window) {
    this.ref = data.refNum;
  }

  ngOnInit() {
    this.confirmationDialogForm = this.formBuilder.group({
      source: ['', Validators.required]
    });
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
    }
  }
  closeDialog(){
    this.confirmationDialogRef.close();
  }

}
