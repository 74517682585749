import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import {NavigatorNotification} from '../navigator-notification';
import {environment} from '../../../../environments/environment';
import {FileObj} from './file.model';

@Component({
  selector: 'app-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.css']
})
export class SupportingDocumentsComponent implements OnInit, OnChanges {
  documentsFormGroup: FormGroup;
  @Input() existingCustomer: boolean;
  @Input() merchantEmail:string;
  @Input() businessType: string;
  @Input() numOwners: number;
  @Input() referenceNumber:string;
  @Input() businessName:string;
  @Input() merchantName:string;
  filesToUpload: FileObj[];
  businesOwnersArray: number[];
  zmbizi = false;
  constructor(private formBuilder: FormBuilder, private navigatorLookupService: NavigatorLookupService, private window: Window) {
  }

  ngOnInit() {
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
    }
    this.documentsFormGroup = this.formBuilder.group({
      businessAddress: [''],
      bankAccount:[''],
      regDocument:[''],
      resolution:[''],
      shareCertificate:[''],
      registrationCertificate:[''],
      foundingDocs:[''],
      letterOfAuthority:[''],
      trustDeed:[''],
      liquorLicense:[''],
      gymAddendum:[''],
      webAudit:[''],
      documents: new FormArray([])
    });
    this.filesToUpload = [];
    for (let i = 0; i < this.numOwners; i++) {
      this.getDocuments.push(this.formBuilder.group({
        idDocument: new FormControl('', []),
        homeAddress: new FormControl('', [])
      }));
    }
    // TODO: figure out this maths
    for (let i = 0; i < ((this.navigatorLookupService.ownerNumbers.length - 1) * 2) + 20; i++) {
      this.filesToUpload.push(new FileObj());
      this.filesToUpload[i] = {fileName: '', file: undefined, submitted: false};
    }
    this.businesOwnersArray = [];
    for (let i = 0; i < this.numOwners; i++) {
      this.businesOwnersArray.push(i);
    }

  }

  ngAfterViewInit(){
    // TODO: move condition check here
    document.getElementsByName("icondoc").forEach((el)=>{
      // from here
      if(this.zmbizi){
        el.setAttribute("src","../../../../assets/zmbizi/icon-document.png");
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.documentsFormGroup) {
      if (changes['numOwners']) {
        this.addDocuments();
        this.businesOwnersArray = [];
        for (let i = 0; i < this.numOwners; i++) {
          this.businesOwnersArray.push(i);
        }
      }

    }
  }

  get getDocuments() {
    return this.documentsFormGroup.get('documents') as FormArray;
  }

  addDocuments() {
    const numberOfOwners = this.numOwners;
    if (this.getDocuments.length < numberOfOwners) {
      for (let i = this.getDocuments.length; i < numberOfOwners; i++) {
        this.getDocuments.push(this.formBuilder.group({
          idDocument: new FormControl('', []),
          homeAddress: new FormControl('', [])
        }));
      }
    } else {
      for (let i = this.getDocuments.length; i >= numberOfOwners; i--) {
        this.getDocuments.removeAt(i);
      }
    }
  }

  fileUploadListener($event: any, index: number): void {
    this.parsePDF($event, $event.target, index);
  }

  parsePDF($event, pdf: any, index: number): void {
    const file: File = pdf.files[0];
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    const input={};
    input[$event.target.getAttribute('formControlName')]=file.name;
    reader.onloadend = () => {
      this.navigatorLookupService.scanFile(file,this.referenceNumber,this.merchantEmail).subscribe(data => {
        this.filesToUpload[index] = {
            fileName: file.name,
            file: file,
            submitted: true
          };
        },
          error => {
            $event.target.value = '';
            this.filesToUpload[index] = {
                fileName: '',
                file: undefined,
                submitted: false
              };
            this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Upload failed', 'OK', 5000));
      });
    }
  };
  sendDocumentEmail() {
    const mailBody = encodeURIComponent(`Good day, \n\nPlease find attached the Business document from the below Merchant. \n\nBusiness Name: ${this.businessName}\nContact Name: ${this.merchantName}\n\nThank you.`);
    const referenceNumber = this.referenceNumber;
    const win = window.open(`mailto:${environment.callMeBackEmailTo}?subject=Upload Document ${referenceNumber}&body=${mailBody}`);
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      win.close();
    }, 500);
  }
  addition(index){
    return index+parseInt(String(this.numOwners));
  }
}
