import {Component, OnInit} from '@angular/core';
import {YoutubeModalComponent} from '../youtube-modal/youtube-modal.component';
import {MatDialog} from '@angular/material';
import {DtmAnalyticsServiceLaunch} from '../../dtm-analytics-service-launch'

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit {
  constructor(public dialog: MatDialog,
              private dtm:DtmAnalyticsServiceLaunch
  ) {}

  openDialog(Title: string, url: string): void {
    const dialogRef = this.dialog.open(YoutubeModalComponent, {
      width: '50%',
      height: '45%',
      data: {youtubeID: url, title: Title}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  ngOnInit() {
  }
}
