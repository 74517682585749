import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, AbstractControl, FormControl} from '@angular/forms';
import {BusinessLocationComponent} from '../navigator/business-location/business-location.component';
import {ProductInfoComponent} from '../navigator/product-info/product-info.component';
import {BankingDetailsComponent} from '../navigator/banking-details/banking-details.component';
import {PricingStructureComponent} from '../navigator/pricing-structure/pricing-structure.component';
import {BusinessInfoComponent} from '../navigator/business-info/business-info.component';
import {BusinessOwnersComponent} from '../navigator/business-owners/business-owners.component';
import {ExistingCustomerComponent} from '../navigator/existing-customer/existing-customer.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../navigator/confirmation-dialog/confirmation-dialog.component';
import {HTTPStatus} from '../../services/http-status/http-status.service';
import {NavigatorLookupService} from '../navigator/navigator-lookup.service';
import {Observable, of} from 'rxjs';
import {NavigatorNotification} from '../navigator/navigator-notification';
import {Router, RouterEvent} from "@angular/router";
import { DtmAnalyticsServiceLaunch } from 'src/app/dtm-analytics-service-launch';

@Component({
  selector: 'app-simplyblu',
  templateUrl: './simplyblu.component.html',
  styleUrls: ['./simplyblu.component.css']
})
export class SimplybluComponent implements OnInit {
  product='SIMPLY_BLU';
  results$: Observable<any>;
  HTTPActivity: boolean;
  numOwners;
  status = true;
  sample: any = {};
  businessTypePass;
  pricingPageClicked = false;
  step = 0;
  existingCustomerData = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : {};
  existingCustomer = this.existingCustomerData.existingCustomer;
  merchantEmail=this.existingCustomerData?this.existingCustomerData.merchantEmailAddress:'';
  referenceNumber = this.existingCustomerData ? this.existingCustomerData.referenceNumber : '';
  productData = localStorage.getItem('product') ? JSON.parse(localStorage.getItem('product')) : {};
  dinersClub: boolean = this.productData ? this.productData.dinersclub && !this.productData.dinersClubType : false;
  businessInfoData: any = localStorage.getItem('businessInfo') ? JSON.parse(localStorage.getItem('businessInfo')) : {};
  businessName = this.businessInfoData ? this.businessInfoData.businessTradingName : '';
  businessOwnerData: any = localStorage.getItem('businessOwner') ? JSON.parse(localStorage.getItem('businessOwner')) : {};
  merchantName: string = this.businessOwnerData.businessOwners ? this.businessOwnerData.businessOwners.length > 0 ? this.businessOwnerData.businessOwners[0].ownerName : '' : '';
  businessLocationData: any = localStorage.getItem('businessLocation') ? JSON.parse(localStorage.getItem('businessLocation')) : {};
  bankingData: any = localStorage.getItem('banking') ? JSON.parse(localStorage.getItem('banking')) : {};
  pricingData: any = localStorage.getItem('pricing') ? JSON.parse(localStorage.getItem('pricing')) : {};
  // @ts-ignore
  @ViewChild('stepper') stepper;
  // @ts-ignore
  @ViewChild(ProductInfoComponent) productInfo: ProductInfoComponent;
  // @ts-ignore
  @ViewChild(ExistingCustomerComponent) existingCustomerInfo: ExistingCustomerComponent;
  // @ts-ignore
  @ViewChild(BusinessLocationComponent) businessLocation: BusinessLocationComponent;
  // @ts-ignore
  @ViewChild(BusinessInfoComponent) businessInfo: BusinessInfoComponent;
  // @ts-ignore
  @ViewChild(BankingDetailsComponent) bankingDetails: BankingDetailsComponent;
  // @ts-ignore
  @ViewChild(PricingStructureComponent) pricingDetails: PricingStructureComponent;
  // @ts-ignore
  @ViewChild(BusinessOwnersComponent) businessOwner: BusinessOwnersComponent;

  get productInfoComponent() {
    return this.productInfo ? this.productInfo.productInfoFormGroup : null;
  }

  get existingCustomerComponent() {
    return this.existingCustomerInfo ? this.existingCustomerInfo.existingCustomerFormGroup : null;
  }

  get businessLocationComponent() {
    return this.businessLocation ? this.businessLocation.businessLocationFormGroup : null;
  }

  get businessInfoComponent() {
    return this.businessInfo ? this.businessInfo.businessInfoFormGroup : null;
  }

  get bankingDetailsComponent() {
    return this.bankingDetails ? this.bankingDetails.bankingDetailsFormGroup : null;
  }

  get pricingComponent() {
    return this.pricingDetails ? this.pricingDetails.pricingStructureFormGroup : null;
  }


  get businessOwnerComponent() {
    return this.businessOwner ? this.businessOwner.businessOwnersFormGroup : null;
  }

  seventhFormGroup: FormGroup;
  eightthFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private confirmationDialog: MatDialog, private httpStatus: HTTPStatus, private navigatorLookupService: NavigatorLookupService,private router: Router, private dtm: DtmAnalyticsServiceLaunch) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.HTTPActivity = status;
    });

  }

  ngOnInit() {
    if (history.state.data) {
      localStorage.removeItem('customer');
      localStorage.removeItem('businessLocation');
      localStorage.removeItem('product');
      localStorage.removeItem('banking');
      localStorage.removeItem('pricing');
      localStorage.removeItem('termsAndConditions');
      localStorage.removeItem('businessInfo');
      localStorage.removeItem('businessOwner');
    }
    this.seventhFormGroup = this.formBuilder.group({
      sevenCtrl: ['']
    });
    this.eightthFormGroup = this.formBuilder.group({
      eightCtrl: ['']
    });
    this.dtm.updatePageName("simplyblu:online merchant application form: Step 1 Existing Customer");
  }

  setNumberOfOwners(numberOwner: number) {
    this.numOwners = numberOwner;
  }

  setBusinessType(businessType: string) {
    this.businessTypePass = businessType;
  }

  addExistingCustomer(data: any) {
    localStorage.setItem('customer', data);
    if (data) {
      const customer = JSON.parse(data);
      this.referenceNumber = customer.referenceNumber;
      this.merchantEmail=customer.merchantEmailAddress;
    }
    this.existingCustomerData = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : {};
  }

  addCustomerType(type: boolean) {
    this.existingCustomer = type;
  }

  addProductInfo(data: any) {
    localStorage.setItem('product', data);
    if (data) {
      const product = JSON.parse(data);
      this.dinersClub = product.dinersclub && !product.dinersClubType;
      this.product=product.productName;
    }
    this.productData = localStorage.getItem('product') ? JSON.parse(localStorage.getItem('product')) : {};
  }

  addBusinessInfo(data: any) {
    localStorage.setItem('businessInfo', data);
    if (data) {
      const businessInfo = JSON.parse(data);
      this.businessName = businessInfo.businessTradingName;
    }
    this.businessInfoData = localStorage.getItem('businessInfo') ? JSON.parse(localStorage.getItem('businessInfo')) : {};
  }

  addBusinessOwner(data: any) {
    localStorage.setItem('businessOwner', data);
    if (data) {
      const owners = JSON.parse(data);
      this.merchantName = owners.businessOwners.length > 0 ? owners.businessOwners[0].ownerName : '';
    }
    this.businessOwnerData = localStorage.getItem('businessOwner') ? JSON.parse(localStorage.getItem('businessOwner')) : {};
  }

  addBusinessLocation(data: any) {
    localStorage.setItem('businessLocation', data);
    this.businessLocationData = localStorage.getItem('businessLocation') ? JSON.parse(localStorage.getItem('businessLocation')) : {};
  }


  addPricing() {
    this.pricingPageClicked = true;
    if (this.pricingComponent.invalid) {
      return false;
    }
  }

  addBanking(data: any) {
    localStorage.setItem('banking', data);
    this.bankingData = localStorage.getItem('banking') ? JSON.parse(localStorage.getItem('banking')) : {};
  }

  addPricingStructure(data: any) {
    localStorage.setItem('pricing', data);
    this.pricingData = localStorage.getItem('pricing') ? JSON.parse(localStorage.getItem('pricing')) : {};
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    switch (this.step) {
      case 0:
        if (this.productInfoComponent.invalid) {
          return false;
        }
        break;
      case 1:
        if (this.businessInfoComponent.invalid) {
          return false;
        }
        break;
      case 2:
        if (this.businessLocationComponent.invalid) {
          return false;
        }
        break;
      case 3:
        if (this.businessOwnerComponent.invalid) {
          return false;
        }
        break;
      case 4:
        if (this.bankingDetailsComponent.invalid) {
          return false;
        }
        break;
      case 5:
        if (this.pricingComponent.invalid) {
          return false;
        }
        break;
      default:
        return false;
    }
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  submitForm() {
    if (this.productInfoComponent.invalid) {
      this.step = 0;
      return false;
    } else if (this.businessInfoComponent.invalid) {
      this.step = 1;
      return false;
    } else if (this.businessLocationComponent.invalid) {
      this.step = 2;
      return false;
    } else if (this.businessOwnerComponent.invalid) {
      this.step = 3;
      return false;
    } else if (this.bankingDetailsComponent.invalid) {
      this.step = 4;
      return false;
    } else if (this.pricingComponent.invalid) {
      this.step = 5;
      return false;
    } else {
      this.submitDraft();

    }

  }

  openConfirmationDialog(referenceNumber) {
    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      width: '580px', height: '420px',
      data: {refNum: referenceNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      const data:any={};
      data.status='delete'
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigate(['/simplyblu'],{state: {data}});
      location.reload();
    });
  }

  stepperHandler(index) {
    this.stepper.selectedIndex = index;
  }

  getProductRequest(): any {
    const product: any = {};
    product.productName = this.productInfoComponent.get('productName').value;
    product.productOption = this.productInfoComponent.get('productOption').value;
    if (product.productOption === 'one' || product.productOption === 'two') {
      product.choiceOfDomain = this.productInfoComponent.get('choiceOfDomain').value;
      if(product.choiceOfDomain==='two'){
        product.domainName = this.productInfoComponent.get('domainName').value;
      }
    }
    if(product.productOption === 'three' || product.productOption === 'four'){
      product.merchantWebsiteAddress = this.productInfoComponent.get('merchantWebsiteAddress').value;
    }
    if (this.productInfoComponent.get('dinersclub').value) {
      product.dinersClub = true;
      product.dinersClubType = this.productInfoComponent.get('dinersClubType').value;
      if (product.dinersClubType) {
        product.dinersclubnum = this.productInfoComponent.get('dinersclubnum').value;
      }
    }
    if (this.productInfoComponent.get('americanExpress').value) {
      product.americanExpress = true;
      product.americanexpressnum = this.productInfoComponent.get('americanexpressnum').value;
    }
    return product;
  }

  getBusinessInfoRequest(): any {
    const businessInfo: any = {};
    businessInfo.businessType = this.businessInfoComponent.get('businessType').value;
    businessInfo.businessTradingName = this.businessInfoComponent.get('businessTradingName').value;
    businessInfo.annualTurnOver = this.businessInfoComponent.get('annualTurnOver').value;
    businessInfo.businessNature = this.businessInfoComponent.get('businessNature').value;
    businessInfo.merchantIndustry = this.businessInfoComponent.get('merchantIndustry').value;
    if (businessInfo.businessType !== 'SOLE_PROPRIETOR') {
      businessInfo.numberOfOwners = this.businessInfoComponent.get('numberOfOwners').value;
    }
    if (businessInfo.businessType !== 'SOLE_PROPRIETOR' && businessInfo.businessType !== 'PARTNERSHIP') {
      businessInfo.businessRegName = this.businessInfoComponent.get('businessRegName').value;
      businessInfo.businessRegNumber = this.businessInfoComponent.get('businessRegNumber').value;
      businessInfo.businessVATNumber = this.businessInfoComponent.get('businessVATNumber').value;
      businessInfo.numberOfShareholders = this.businessInfoComponent.get('numberOfShareholders').value;
      businessInfo.shareRequests = this.businessInfoComponent.get('shares').value;
    }
    return businessInfo;
  }

  getBusinessOwnersRequest():any[] {
    const businessOwners: any[] = [];
    for (let i = 0; i < this.businessOwnerComponent.get('businessOwners').value.length; i++) {
      const businessOwner: any = {};
      businessOwner.contactName = this.businessOwnerComponent.get('businessOwners').value[i].ownerName;
      businessOwner.emailAddress = this.businessOwnerComponent.get('businessOwners').value[i].emailAddress;
      businessOwner.idNumber = this.businessOwnerComponent.get('businessOwners').value[i].idType?this.businessOwnerComponent.get('businessOwners').value[i].passportNumber:this.businessOwnerComponent.get('businessOwners').value[i].ownerIdNumber;
      businessOwner.telNo = this.businessOwnerComponent.get('businessOwners').value[i].contactNumber;
      const fic: any = {};
      fic.publicOfficial = this.businessOwnerComponent.get('businessOwners').value[i].publicOfficialFic ? 'YES' : 'NO';
      fic.publicOfficialRel = this.businessOwnerComponent.get('businessOwners').value[i].relationshipFic ? 'YES' : 'NO';
      fic.publicOfficialRelNature = this.businessOwnerComponent.get('businessOwners').value[i].natureOfRelationship === '' ? null : this.businessOwnerComponent.get('businessOwners').value[i].natureOfRelationship;
      fic.publicOfficialText = this.businessOwnerComponent.get('businessOwners').value[i].publicOfficialName;
      businessOwner.fic=fic;
      if (this.businessInfoComponent.get('businessType').value === 'SOLE_PROPRIETOR' || this.businessInfoComponent.get('businessType').value === 'PARTNERSHIP') {
        const address: any = {};
        address.buildingName= this.businessOwnerComponent.get('businessOwners').value[i].ownerBuildingName;
        address.city= this.businessOwnerComponent.get('businessOwners').value[i].ownerCity;
        address.postalCode= this.businessOwnerComponent.get('businessOwners').value[i].ownerPostalCode;
        address.province= this.businessOwnerComponent.get('businessOwners').value[i].ownerProvince;
        address.streetName= this.businessOwnerComponent.get('businessOwners').value[i].ownerStreet;
        address.suburb= this.businessOwnerComponent.get('businessOwners').value[i].ownerSuburb;
        businessOwner.address=address;
      }
      businessOwners.push(businessOwner);
    }
    return businessOwners;
  }

  submitDraft() {
    const submitRequest = {
      customeremail: this.existingCustomerComponent.get('merchantEmailAddress').value,
      referenceNumber: this.existingCustomerComponent.get('referenceNumber').value,
      existingCustomer: this.existingCustomerComponent.get('existingCustomer').value,
      tableId:sessionStorage.getItem('tableId'),
      tableIdItemId:sessionStorage.getItem('tableIdItemId'),
      issueId:sessionStorage.getItem('issueId'),
      bankRequest: this.bankingDetailsComponent.value,
      businessAddressRequest: {
        address: {
          buildingName: this.businessLocationComponent.get('buildingName').value,
          city: this.businessLocationComponent.get('city').value,
          postalCode: this.businessLocationComponent.get('postalCode').value,
          province: this.businessLocationComponent.get('province').value,
          streetName: this.businessLocationComponent.get('street').value,
          suburb: this.businessLocationComponent.get('suburb').value
        },
        physicalAddressCheck: this.businessInfoComponent.get('physicalAddressCheck') === null ? null : this.businessLocationComponent.get('physicalAddressCheck').value,
        businessEmailAddress: this.businessLocationComponent.get('businessEmailAddress').value,
        businessTelephoneNumber: this.businessLocationComponent.get('businessTelephoneNumber').value,
        primaryNumber: this.businessLocationComponent.get('primaryContactNumber').value,
        primaryPerson: this.businessLocationComponent.get('primaryContactPerson').value
      },
      businessInfoRequest: this.getBusinessInfoRequest(),
      businessOwnerRequests: this.getBusinessOwnersRequest(),
      productRequest: this.getProductRequest(),
      pricing: {
        setupFee: "Free",
        subscriptionFee: "R350.00",
        domesticDebitRate: this.pricingComponent.get("domesticDebitCardRate").value,
        domesticCreditRate: this.pricingComponent.get("domesticCreditCardRate").value,
        internationalCreditRate: this.pricingComponent.get("internationalCardRate").value,
        dinersFee: "N/A"
      }
    };
    this.navigatorLookupService.submitDraft(submitRequest).subscribe(data => {
      sessionStorage.removeItem('tableId');
      sessionStorage.removeItem('tableIdItemId');
      sessionStorage.removeItem('issueId');
      this.openConfirmationDialog(data.body.referenceNumber);
    }, error => {
      this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Technical Error', 'OK', 5000));
    });
  }

  getStepString(index){
    switch (index) {
      case 0:
        return "Existing Customer";
        break;
      case 1:
        return "Product Options";
        break;
      case 2:
        return "Business Info";
        break;
      case 3:
        return "Business Location";
        break;
      case 4:
        return "Business Owner";
        break;
      case 5:
        return "Banking Details";
        break;
      case 6:
        return "Pricing";
        break;
      case 7:
        return "Supporting Documents";
        break;
      case 8:
        return "Submit";
        break;
      default:
        return "false";
    }
  }

  trackMatStep($event) {
    var currentStep = this.getStepString($event.selectedIndex);
    var prevStep = this.getStepString($event.previouslySelectedIndex);

    this.dtm.simplybluClick("Navigational", "Online Merchant Application Form", currentStep, prevStep);

    if ($event.previouslySelectedIndex === 0) {
      this.stepperHandler(0);
      const req = {
        merchantEmail: this.existingCustomerComponent.get('merchantEmailAddress').value,
        referenceNumber: this.existingCustomerComponent.get('referenceNumber').value
      };
      this.navigatorLookupService.getDraft(req).subscribe(data => {
        sessionStorage.setItem('tableId', data.body.tableId);
        sessionStorage.setItem('tableIdItemId', data.body.tableIdItemId);
        sessionStorage.setItem('issueId', data.body.issueId);
        const businessInfo: any = {
          businessTradingName: data.body.businessInfo.businessTradingName,
          businessNature: data.body.businessInfo.businessNature
        };
        const businessOwners: any = {
          businessOwners: [
            {
              ownerName: data.body.businessOwners[0].contactName,
              contactNumber: data.body.businessOwners[0].telephoneNumber,
              emailAddress: data.body.businessOwners[0].emailAddress
            }
          ]
        };
        this.businessInfoData = businessInfo;
        this.businessOwnerData = businessOwners;
        this.numOwners=1;
      }, error => {
        this.stepperHandler(0);
        if (error.status === 404) {
          this.navigatorLookupService.displayErrorAction(new NavigatorNotification('No applications found', 'OK', 5000));

        } else {
          this.navigatorLookupService.displayErrorAction(new NavigatorNotification('Technical Error', 'OK', 5000));
        }

      });
    }

    const step = $event.selectedIndex + 1;
    if(currentStep.includes("|")){
        this.dtm.updatePageName("simplyblu:Online Merchant Application Form:" + "Step " + step + " " + currentStep.substring(0, currentStep.indexOf("|")));
    }
    else{
        this.dtm.updatePageName("simplyblu:Online Merchant Application Form:" + "Step " + step + " " + currentStep);
    }
  }
}
