import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigatorNotification} from './navigator-notification';

@Injectable({
  providedIn: 'root'
})
export class NavigatorLookupService {

  zmbizi = false;
  constructor(private httpClient: HttpClient,private snackBar: MatSnackBar, private window: Window) {
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
      this.disableZmbiziOptions();
    }
  }

  disableZmbiziOptions(){
    this.bankingTimes[0].disabled = true; //Manual banking time
    this.bankingTimes[1].disabled = true; //Automatic AM banking time

    this.billingCycles[1].disabled = true; //monthly billing cycle

    // Only enable Retail and Restaurant business types for zmbizi
    for(let i = 2; i < 11; i++){
      this.merchantIndustries[i].disabled = true;
    }

  }

  provinces: LookUp[] = [
    {code: 'EASTERN_CAPE', name: 'Eastern Cape', disabled: false},
    {code: 'FREE_STATE', name: 'Free State', disabled: false},
    {code: 'GAUTENG', name: 'Gauteng', disabled: false},
    {code: 'KWAZULU_NATAL', name: 'KwaZulu-Natal', disabled: false},
    {code: 'LIMPOPO', name: 'Limpopo', disabled: false},
    {code: 'MPUMALANGA', name: 'Mpumalanga', disabled: false},
    {code: 'NORTH_WEST', name: 'North-West', disabled: false},
    {code: 'NORTHERN_CAPE', name: 'Northern Cape', disabled: false},
    {code: 'WESTERN_CAPE', name: 'Western Cape', disabled: false},
  ];
  productOptions: LookUp[] = [
    {code: 'six', name: 'Payment Gateway Plugin + PayByLink', disabled: false},
    {code: 'seven', name: 'Online store + payment gateway + PayByLink', disabled: false},
    {code: 'eight', name: 'PayByLink only', disabled: false},
    {code: 'nine',name:'Payment Gateway Plugin only',disabled:false}
  ];
  paymentGateways: LookUp[] = [
      {code: 'Ecentric', name: 'Ecentric', disabled: false},
      {code: 'PayGate', name: 'PayGate', disabled: false},
      {code: 'MyGate by Wirecard', name: 'MyGate by Wirecard', disabled: false},
      {code: 'PayU',name:'PayU',disabled:false},
      {code: 'Mastercard Payment Gateway Services (MPGS)', name: 'Mastercard Payment Gateway Services (MPGS)', disabled: false},
      {code: 'CyberSource by Visa', name: 'CyberSource by Visa', disabled: false},
    ];
    simplyBluPaymentGateways: LookUp[] = [
      {code: 'Shopify', name: 'Shopify', disabled: false},
      {code: 'WooCommerce', name: 'WooCommerce', disabled: false},
      {code: 'PrestaShop', name: 'PrestaShop', disabled: false},
      {code: 'X-Cart',name:'X-Cart',disabled:false},
      {code: 'OpenCart', name: 'OpenCart', disabled: false},
      {code: 'Magento', name: 'Magento', disabled: false},
      {code: 'Drupal', name: 'Drupal', disabled: false},
      {code: 'WP eCommerce', name: 'WP eCommerce', disabled: false},
      {code: 'nopCommerce', name: 'nopCommerce', disabled: false},
      {code: 'Joomla – VirtueMart', name: 'Joomla – VirtueMart', disabled: false},
      {code: 'osCommerce', name: 'osCommerce', disabled: false},
    ];
    electronicBillPaymentGateways: LookUp[] = [
      {code: 'Ecentric', name: 'Ecentric', disabled: false},
      {code: 'PayGate', name: 'PayGate', disabled: false},
      {code: 'MyGate by Wirecard', name: 'MyGate by Wirecard', disabled: false},
      {code: 'PayU',name:'PayU',disabled:false}
    ];
    recurringPaymentGateways: LookUp[] = [
      {code: 'Ecentric', name: 'Ecentric', disabled: false},
      {code: 'PayGate', name: 'PayGate', disabled: false},
      {code: 'MyGate by Wirecard', name: 'MyGate by Wirecard', disabled: false}
    ];
    paymentMethods: LookUp[] = [
          {code: 'one', name: 'Card', disabled: false},
          {code: 'two', name: 'Card + MasterPass', disabled: false}
        ];
  choiceOfDomains: LookUp[] = [
    {code: 'one', name: 'SimplyBlu domain (https://store-name.store.simplyblu.co.za)', disabled: false},
    {code: 'two', name: 'My own domain (e.g. https://store-name.com)', disabled: false}
  ];
  paymentSolutions: LookUp[] = [
    {code: 'SIMPLY_BLU', name: 'SimplyBlu', disabled: false},
    {code: 'VAS', name: 'VAS', disabled: false},
    {code:'AUTO_LINK',name:'AutoLink',disabled:false},
    {code:'POCKET_BIZ',name:'PocketBiz',disabled:false},
    {code:'ECOMMERCE',name:'Ecommerce',disabled:false},
    {code:'RECURRING_PAYMENTS',name:'Recurring Payments',disabled:false},
    {code:'MASTER_PASS',name:'MasterPass',disabled:false},
    {code:'ELECTRONIC_BILL',name:'Electronic Bill Payments',disabled:false},
    {code:'ANDROID_SOFTPOS',name:'SoftPOS',disabled:false}
  ];

  bankingTimes: LookUp[] = [
    {code: '1', name: 'Manual', disabled: false},
    {code: '2', name: 'Automatic (AM)', disabled: false},
    {code: '3', name: 'Automatic (PM)', disabled: false}
  ];
  billingCycles: LookUp[] = [
    {code: '1', name: 'Daily', disabled: false},
    {code: '2', name: 'Monthly', disabled: false}
  ];
  portableDeviceModels: LookUp[] = [
    {code: 'Verifone - V240m', name: 'Verifone - V240m', disabled: false},
    {code:'Verifone – VX675',name:'Verifone – VX675',disabled:false},
    {code:'Verifone – VX680',name:'Verifone – VX680',disabled:false},
    {code:'Ingenico – IWL 220',name:'Ingenico – IWL 220',disabled:false},
    {code:'Ingenico – IWL 250',name:'Ingenico – IWL 250',disabled:false}
    ];
    counterTopDeviceModels: LookUp[] = [
      {code: 'Verifone – VX520', name: 'Verifone – VX520', disabled: false},
      {code: 'Ingenico – ICT220', name: 'Ingenico – ICT220', disabled: false}
      ];
      numCounterTopDevices: LookUp[] = [
        {code: '1', name: '1', disabled: false},
        {code: '2', name: '2', disabled: false},
        {code: '3', name: '3', disabled: false},
        {code: '4', name: '4', disabled: false},
        {code: '6', name: '6', disabled: false},
        {code: '7', name: '7', disabled: false},
        {code: '8', name: '8', disabled: false},
        {code: '9', name: '9', disabled: false},
        {code: '10', name: '10', disabled: false}
        ];
        numVASDevices: LookUp[] = [
          {code: '1', name: '1', disabled: false},
          {code: '2', name: '2', disabled: false},
          {code: '3', name: '3', disabled: false},
          {code: '4', name: '4', disabled: false},
          {code: '6', name: '6', disabled: false},
          {code: '7', name: '7', disabled: false},
          {code: '8', name: '8', disabled: false},
          {code: '9', name: '9', disabled: false},
          {code: '10', name: '10', disabled: false}
          ];
        numPortableDevices: LookUp[] = [
          {code: '1', name: '1', disabled: false},
        {code: '2', name: '2', disabled: false},
        {code: '3', name: '3', disabled: false},
        {code: '4', name: '4', disabled: false},
        {code: '6', name: '6', disabled: false},
        {code: '7', name: '7', disabled: false},
        {code: '8', name: '8', disabled: false},
        {code: '9', name: '9', disabled: false},
        {code: '10', name: '10', disabled: false}
          ];
  ownerNumbers: LookUp[] = [
    {code: '1', name: '1', disabled: false},
    {code: '2', name: '2', disabled: false},
    {code: '3', name: '3', disabled: false},
    {code: '4', name: '4', disabled: false},
    {code: '5', name: '5', disabled: false},
    {code: '6', name: '6', disabled: false},
    {code: '7', name: '7', disabled: false},
    {code: '8', name: '8', disabled: false},
    {code: '9', name: '9', disabled: false},
    {code: '10', name: '10', disabled: false},
    {code: '11', name: '11 and more', disabled: false}
  ];
  businessTypes: LookUp[] = [
    {code: 'SOLE_PROPRIETOR', name: 'Sole Proprietor', disabled: false},
    {code: 'PARTNERSHIP', name: 'Partnership', disabled: false},
    {code: 'PRIVATE_COMPANY', name: 'Private Company (PTY)', disabled: false},
    {code: 'CLOSE_CORPORATION', name: 'Close Corporation (CC)', disabled: false},
    {code: 'PUBLIC_COMPANY', name: 'Public Listed Company (PLC)', disabled: false},
    {code: 'TRUST', name: 'Trust', disabled: false},
    {code: 'NGO_NPO', name: 'NGO / NPO', disabled: false},
  ];

  shareholderBusinessTypes: string[] = ["NGO_NPO","PRIVATE_COMPANY","PUBLIC_COMPANY"];

  merchantIndustries: LookUp[] = [
    {code: 'RETAIL', name: 'Retail', disabled: false},
    {code: 'RESTAURANT', name: 'Restaurant', disabled: false},
    {code: 'GROCERY_STORES', name: 'Grocery Stores', disabled: false},
    {code: 'HARDWARE', name: 'Building & Hardware', disabled: false},
    {code: 'ENTERTAINMENT', name: 'Travel & Entertainment', disabled: false},
    {code: 'ACCOMMODATION', name: 'Accommodation, Lodges & Hotels', disabled: false},
    {code: 'CHILDREN_AND_EDUCATION', name: 'Children & Education', disabled: false},
    {code: 'HEALTHCARE', name: 'Health Care & Pharmacies', disabled: false},
    {code: 'MOTOR_SPARES', name: 'Spares & repairs/ Tyres / Car Batteries', disabled: false},
    {code: 'FUEL', name: 'Petrol Station / Fuel & Oil', disabled: false},
    {code: 'SERVICE_BUSINESS', name: 'Service Business', disabled: false},
  ];
  // TODO: Make MC code a variable outside of the code
  mcCodeList: string[] = ['MB01', 'TR02', 'DS03', 'RK04', 'BP05', 'MS06', 'NM07', 'PO08', 'LS09', 'NM10', 'MM11', 'TT12','CC13', 'CB14'];

  permitTypes: string[] = [
    'Asylum Permit',
    'Business VISA',
    'Crew Visa',
    'Critical Skills Work Visa',
    'Diplomat Permit/Visa',
    'Exchange Visa',
    'General Work Visa',
    'Intra-Company Transfer Work Visa',
    'Medical Treatment Visa',
    'Permanent Residence Permit',
    'Quota Work Visa',
    'Refugee Permit',
    'Relative Visa',
    'Retired Person Visa',
    'Study Visa',
    'Treaty Visa',
    'Visitor Visa'
  ];

  maritalStatuses: string[] = [
    'Divorced',
    'Married',
    'Separated',
    'Single',
    'Unclassified',
    'Widowed'
  ];

  maritalTypes: string[] = [
    'Common Law Spouse',
    'Foreign Matrimonial Systems',
    'Micop after 1/12/93',
    'Mocop ANC incl Without Accrual after 1/12/93',
    'Mocop With Accrual after 1/12/93',
    'Traditional Marriage'
  ];

  sexes: LookUp[] = [
    {code: 'MALE', name: 'Male', disabled: false},
    {code: 'FEMALE', name: 'Female', disabled: false}
  ];

  shareHolderNumbers: LookUp[] = [
    {code: '1', name: '1', disabled: false},
    {code: '2', name: '2', disabled: false},
    {code: '3', name: '3', disabled: false},
    {code: '4', name: '4', disabled: false},
    {code: '5', name: '5', disabled: false},
    {code: '6', name: '6', disabled: false},
    {code: '7', name: '7', disabled: false},
    {code: '8', name: '8', disabled: false},
    {code: '9', name: '9', disabled: false},
    {code: '10', name: '10', disabled: false}
  ];
  numberOfDevicesLinked: LookUp[] = [
    {code: '1', name: '1', disabled: false},
    {code: '2', name: '2', disabled: false},
    {code: '3', name: '3', disabled: false},
    {code: '4', name: '4', disabled: false},
    {code: '5', name: '5', disabled: false},
    {code: '6', name: '6', disabled: false},
    {code: '7', name: '7', disabled: false},
    {code: '8', name: '8', disabled: false},
    {code: '9', name: '9', disabled: false},
    {code: '10', name: '10', disabled: false},
    {code: '11', name: '11', disabled: false},
    {code: '12', name: '12', disabled: false},
    {code: '13', name: '13', disabled: false},
    {code: '14', name: '14', disabled: false},
    {code: '15', name: '15', disabled: false},
    {code: '16', name: '16', disabled: false},
    {code: '17', name: '17', disabled: false},
    {code: '18', name: '18', disabled: false},
    {code: '19', name: '19', disabled: false},
    {code: '20', name: '20', disabled: false}
  ];
  countries:any[]=[
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ];
  relationships: LookUp[] = [
    {code: 'SPOUSE_OR_PARTNER', name: 'Spouse/Partner', disabled: false},
    {code: 'SON_OR_DAUGHTER', name: 'Son/Daughter', disabled: false},
    {code: 'PARENT', name: 'Parent', disabled: false},
    {code: 'SIBLING', name: 'Sibling', disabled: false},
    {code: 'BUSINESS_PARTNER', name: 'Business Partner', disabled: false},
    {code: 'CLOSE_ASSOCIATE', name: 'Close Associate', disabled: false}
  ];
  sources: LookUp[] = [
    {code: '1', name: 'Facebook', disabled: false},
    {code: '2', name: 'Google/Search engine', disabled: false},
    {code: '3', name: 'Word of mouth', disabled: false},
    {code: '4', name: 'Instagram', disabled: false},
  ];
  setUpFees:string[]=['R711,85 (incl. VAT)', 'Fee waived'];
  private createHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
    return headers;
  }

  getDraft(request:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-ibm-client-id': 'e9c7683d-fca1-418b-9d81-fe41da8e46de',
        'x-ibm-client-secret': 'I1eD5mP0nJ7qH2tS0aH4dN1hD4lQ5jU0aW0dW1pC6rE8cM6bD6',
        Accept:['application/json','text/plain', '*/*']
      })
    };
    return this.httpClient.post(`${environment.baseUrl}/QueryApplication?orig=navigator`, request,{headers: new HttpHeaders({
        'Content-Type': 'application/json',referenceNumber:'hello'
      }),observe: 'response'});
  }
  getBanks(): Observable<any> {
    return this.httpClient.get(`${environment.branchUrl}/banks`);
  }
  getBranches(bankCode:string,branchName:string): Observable<any> {
    const getparams = new HttpParams().set('bankname',bankCode).set('branchname', branchName);
    return this.httpClient.get(`${environment.branchUrl}/branches`,{params:getparams});
  }
  submitDraft(request:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-ibm-client-id': 'e9c7683d-fca1-418b-9d81-fe41da8e46de',
        'x-ibm-client-secret': 'I1eD5mP0nJ7qH2tS0aH4dN1hD4lQ5jU0aW0dW1pC6rE8cM6bD6',
        Accept:['application/json','text/plain', '*/*']
      })
    };
    return this.httpClient.post(`${environment.baseUrl}/submitApplication?orig=navigator`, request,{headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),observe: 'response'});
  }
  createDraft(request:any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-ibm-client-id': 'e9c7683d-fca1-418b-9d81-fe41da8e46de',
        'x-ibm-client-secret': 'I1eD5mP0nJ7qH2tS0aH4dN1hD4lQ5jU0aW0dW1pC6rE8cM6bD6',
        Accept:['application/json','text/plain', '*/*']
      })
    };
    return this.httpClient.post(`${environment.baseUrl}/createApplication?orig=navigator`, request,{headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),observe: 'response'});
  }
  displayErrorAction(notification: NavigatorNotification) {
    const snackBarRef = this.snackBar.open(notification.message, notification.action, {
      duration: notification.duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['error-notification']
    });

    snackBarRef.afterDismissed().subscribe(null, null, () => {

    });
  }
  scanFile(fileToUpload: File,referenceNumber:string,merchantEmail:string): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('fileName', fileToUpload.name);
    formData.append('fileSize', fileToUpload.size.toString());
    formData.append('fileType', fileToUpload.type);
    return this.httpClient.post(`/fileupload-aws/application`, formData, {headers: new HttpHeaders({
        'referenceNumber':referenceNumber,'merchantEmail':merchantEmail
      }),observe: 'response'});
  }
  getUserType():string{
    return sessionStorage.getItem('usertype');
  }
}
