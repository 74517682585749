import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DevicePickerDialogComponent } from '../device-picker-dialog/device-picker-dialog.component';
import {NavigatorLookupService} from '../navigator-lookup.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css']
})
export class ProductInfoComponent implements OnInit,OnChanges {
  public productInfoFormGroup: FormGroup;
  productOptions: any = this.navigatorLookupService.productOptions;
  choiceOfDomains: any = this.navigatorLookupService.choiceOfDomains;
  paymentGateways: any = this.navigatorLookupService.paymentGateways;
  simplyBluPaymentGateways: any = this.navigatorLookupService.simplyBluPaymentGateways;
  electronicBillPaymentGateways: any = this.navigatorLookupService.electronicBillPaymentGateways;
  recurringPaymentGateways:any=this.navigatorLookupService.recurringPaymentGateways;
  paymentMethods: any = this.navigatorLookupService.paymentMethods;
  paymentSolutions: any = this.navigatorLookupService.paymentSolutions;
  bankingTimes:any=this.navigatorLookupService.bankingTimes;
  billingCycles:any=this.navigatorLookupService.billingCycles;
  portableDeviceModels: any = this.navigatorLookupService.portableDeviceModels;
  counterTopDeviceModels: any = this.navigatorLookupService.counterTopDeviceModels;
  numPortableDevices: any = this.navigatorLookupService.numPortableDevices;
  numCounterTopDevices: any = this.navigatorLookupService.numCounterTopDevices;
  numDevicesToLinkLookup: any = this.navigatorLookupService.numberOfDevicesLinked;
  todayDate: Date = new Date();
  formData:any;
  usertype:string=this.navigatorLookupService.getUserType();
  @Input() data:any;
  @Input() isClicked: boolean;
  @Input() product:string;
  @Output() outPutData = new EventEmitter<any>();
  zmbizi = false;
  constructor(private formBuilder: FormBuilder,private navigatorLookupService: NavigatorLookupService,private deviceDialog: MatDialog, private window: Window) { }

  ngOnInit() {
    this.productInfoFormGroup = this.formBuilder.group({
      productName: [''],
      productOption: [''],
      choiceOfDomain: [''],
      domainName: [''],
      merchantWebsiteAddress: [''],
      paymentGateway:[''],
      simplyBluPaymentGateway:[''],
      electronicBillPaymentGateway:[''],
      recurringPaymentGateway:[''],
      paymentMethod:[''],
      typeOfDevice:[''],
      portableDevice:[],
      counterTopDevice:[],
      portableDeviceModel:[],
      counterTopDeviceModel:[],
      numCounterTopDevice:[],
      numDevices:[''],
      numPortableDevice:[],
      whenDevReq:[],
      dinersclub: [],
      americanExpress: [],
      rcs:[],
      prepaidSolutions: [false],
      airTime:[],
      electricity:[],
      instantMoney:[],
      dinersClubType: [false],
      dinersclubnum: [''],
      americanexpressnum: [''],
      rcsnum:[''],
      fleet:[],
      garage:[],
      stannicFleet:[],
      wesBankFleet:[],
      absaFleet:[],
      nedFleet:[],
      stannicFleetNumber:[],
      wesBankFleetNumber:[],
      absaFleetNumber:[],
      nedFleetNumber:[],
      cashback:[false],
      refunds:[false],
      bankingTime:[''],
      billingCycle:[''],
      comments:['']
    });
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
      this.initZmBizi();

      // TODO: Update 10 in the loop to be more dynamic
      this.productInfoFormGroup.get('numDevicesToLink').valueChanges.subscribe(value => {
        if(!this.productInfoFormGroup.contains('cellphoneNumbers')){
          for(let i = 0; i < 10; i++){
            this.productInfoFormGroup.addControl('cell'+i, new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]));
          }
          this.clearCellNumbersValidity(value);
          if(value !== null){
            this.productInfoFormGroup.addControl('cellphoneNumbers', new FormControl(new Array(parseInt(value)), Validators.required));
          }
        }
        else{
          for(let i = 0; i < value; i++){
            this.productInfoFormGroup.controls['cell'+i].setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
            this.productInfoFormGroup.controls['cell'+i].updateValueAndValidity();
          }
          this.clearCellNumbersValidity(value);
        }
      });
    }
    this.productInfoFormGroup.patchValue(this.data);
    this.productInfoFormGroup.patchValue({productName: this.product});
    this.productInfoFormGroup.valueChanges.subscribe(selectedValue  => {
      this.outPutData.emit(JSON.stringify(this.productInfoFormGroup.value));
    });
    this.setProductNameValidators1(this.productInfoFormGroup.get('productName').value);
    this.setProductOptionsValidators1(this.productInfoFormGroup.get('productOption').value);
    //this.setChoiceOfDomainsValidators(this.productInfoFormGroup.get('choiceOfDomain').value)
    this.setDinersValidators(this.productInfoFormGroup.get('dinersClubType').value);
    this.setAmericanExpressValidators(this.productInfoFormGroup.get('americanExpress').value);

    // TODO: Update 20 in the loop to be more dynamic
    if(this.productInfoFormGroup.get('numDevicesToLink')){
      this.productInfoFormGroup.get('numDevicesToLink').valueChanges.subscribe(value => {
        if(!this.productInfoFormGroup.contains('cellphoneNumbers')){
          for(let i = 0; i < 20; i++){
            this.productInfoFormGroup.addControl('cell'+i, new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]));
          }
          this.clearCellNumbersValidity(value);
          if(value !== null){
            this.productInfoFormGroup.addControl('cellphoneNumbers', new FormControl(new Array(parseInt(value)), Validators.required));
          }
        }
        else{
          for(let i = 0; i < value; i++){
            this.productInfoFormGroup.controls['cell'+i].setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
            this.productInfoFormGroup.controls['cell'+i].updateValueAndValidity();
          }
          this.clearCellNumbersValidity(value);
        }
      });
    }

    this.productInfoFormGroup.get('productName').valueChanges
      .subscribe(value => {
        this.setProductNameValidators1(value);
        }
      );
    this.productInfoFormGroup.get('productOption').valueChanges
      .subscribe(value => {
        this.setProductOptionsValidators1(value);
        }
      );
    this.productInfoFormGroup.get('choiceOfDomain').valueChanges
      .subscribe(value => {
          //this.setChoiceOfDomainsValidators(value);
        }
      );
    this.productInfoFormGroup.get('dinersClubType').valueChanges
      .subscribe(value => {
        this.setDinersValidators(value);
        }
      );
      this.productInfoFormGroup.get('counterTopDevice').valueChanges
      .subscribe(value => {
        this.setCounterTopValidators(value);
        }
      );
      this.productInfoFormGroup.get('portableDevice').valueChanges
      .subscribe(value => {
        this.setPortableValidators(value);
        }
      );
      this.productInfoFormGroup.get('rcs').valueChanges
      .subscribe(value => {
        this.setRCSValidators(value);
        }
      );
    this.productInfoFormGroup.get('americanExpress').valueChanges
      .subscribe(value => {
        this.setAmericanExpressValidators(value);
        }
      );
      this.productInfoFormGroup.get('stannicFleet').valueChanges
      .subscribe(value => {
        this.setStannicFleetValidators(value);
        }
      );
      this.productInfoFormGroup.get('wesBankFleet').valueChanges
      .subscribe(value => {
        this.setWesBankFleetValidators(value);
        }
      );
      this.productInfoFormGroup.get('absaFleet').valueChanges
      .subscribe(value => {
        this.setAbsaFleetValidators(value);
        }
      );
      this.productInfoFormGroup.get('nedFleet').valueChanges
      .subscribe(value => {
        this.setNedFleetValidators(value);
        }
      );

  }

  clearCellNumbersValidity(value: number){
    for(let i = value; i < 20; i++){
      if(this.productInfoFormGroup.controls['cell'+i] === undefined){
        return;
      }
      this.productInfoFormGroup.controls['cell'+i].clearValidators();
      this.productInfoFormGroup.controls['cell'+i].updateValueAndValidity();
    }
  }
  updateCell($event, i){
    if(this.productInfoFormGroup.get('cellphoneNumbers') !== null){
      this.productInfoFormGroup.get('cellphoneNumbers').value[i] = $event.target.value;
      this.productInfoFormGroup.get("cell"+i).setValue($event.target.value);
    }   
  }

  // ensure we do not display more fields than required
  check(index: number): boolean{
    if(this.productInfoFormGroup.get('numDevicesToLink') != null){
      if(index < this.productInfoFormGroup.get('numDevicesToLink').value){
        return true;
      }
    }
    return false;
  }
  initZmBizi(){
    this.productInfoFormGroup.controls['productName'].setValue('ZMBIZI');
    this.productInfoFormGroup.controls['bankingTime'].setValue('3');
    this.productInfoFormGroup.controls['billingCycle'].setValue('1');
    this.productInfoFormGroup.addControl("numDevicesToLink", new FormControl(null,[Validators.required]));
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.productInfoFormGroup){
      this.productInfoFormGroup.patchValue(this.data);
    }
  }
  get productName(): any {
    return this.productInfoFormGroup.get('productName').value;
  }
  get productOption(): any {
    return this.productInfoFormGroup.get('productOption').value;
  }
  get choiceOfDomain(): any {
    return this.productInfoFormGroup.get('choiceOfDomain').value;
  }
  get portableDevice(): any {
    return this.productInfoFormGroup.get('portableDevice').value;
  }
  get counterTopDevice(): any {
    return this.productInfoFormGroup.get('counterTopDevice').value;
  }
  get numCounterTopDevice(): any {
    return this.productInfoFormGroup.get('numCounterTopDevice').value;
  }
  get numDevices(): any {
    return this.productInfoFormGroup.get('numDevices').value;
  }
  onPortalDeviceChange($event){
    this.productInfoFormGroup.patchValue({portableDevice: $event.checked});
  }
  onCounterTopDeviceChange($event){
    this.productInfoFormGroup.patchValue({counterTopDevice: $event.checked});
  }
  get dinersclub(): any {
    return this.productInfoFormGroup.get('dinersclub').value;
  }

  get dinersClubType(): any {
    return this.productInfoFormGroup.get('dinersClubType').value;
  }

  get americanExpress(): any {
    return this.productInfoFormGroup.get('americanExpress').value;
  }
  get rcs(): any {
    return this.productInfoFormGroup.get('rcs').value;
  }
  get fleet(): any {
    return this.productInfoFormGroup.get('fleet').value;
  }
  get garage(): any {
    return this.productInfoFormGroup.get('garage').value;
  }
  get stannicFleet(): any {
    return this.productInfoFormGroup.get('stannicFleet').value;
  }
  get wesBankFleet(): any {
    return this.productInfoFormGroup.get('wesBankFleet').value;
  }
  get absaFleet(): any {
    return this.productInfoFormGroup.get('absaFleet').value;
  }
  get nedFleet(): any {
    return this.productInfoFormGroup.get('nedFleet').value;
  }
  get prepaidSolutions(): any {
    return this.productInfoFormGroup.get('prepaidSolutions').value;
  }
  get airTime(): any {
    return this.productInfoFormGroup.get('airTime').value;
  }
  get electricity(): any {
    return this.productInfoFormGroup.get('electricity').value;
  }
  get instantMoney(): any {
    return this.productInfoFormGroup.get('instantMoney').value;
  }
    get billingCycle(): any {
       return this.productInfoFormGroup.get('billingCycle').value;
     }
    get paymentGateway(): any {
          return this.productInfoFormGroup.get('paymentGateway').value;
        }
  onDinersClubChange($event) {
    this.productInfoFormGroup.patchValue({dinersclub: $event.checked});
    if(!this.dinersclub){
      this.productInfoFormGroup.get('dinersclubnum').clearValidators();
      this.productInfoFormGroup.get('dinersclubnum').updateValueAndValidity();
    }
  }

  onAmericanExpressChange($event) {
    this.productInfoFormGroup.patchValue({americanExpress: $event.checked});
  }
  onRCSChange($event){
    this.productInfoFormGroup.patchValue({rcs: $event.checked});
  }
  onFleetChange($event){
    this.productInfoFormGroup.patchValue({fleet: $event.checked});
  }
  onStannicFleetChange($event){
    this.productInfoFormGroup.patchValue({stannicFleet: $event.checked});
  }
  onWesBankFleetChange($event){
    this.productInfoFormGroup.patchValue({wesBankFleet: $event.checked});
  }
  onAbsaFleetChange($event){
    this.productInfoFormGroup.patchValue({absaFleet: $event.checked});
  }
  onNedFleetChange($event){
    this.productInfoFormGroup.patchValue({nedFleet: $event.checked});
  }
  onGarageChange($event){
    this.productInfoFormGroup.patchValue({garage: $event.checked});
  }
  onAirTimeChange($event) {
    this.productInfoFormGroup.patchValue({airTime: $event.checked});
  }
  onElectricityChange($event) {
    this.productInfoFormGroup.patchValue({electricity: $event.checked});
  }
  onInstantMoneyChange($event) {
    this.productInfoFormGroup.patchValue({instantMoney: $event.checked});
  }
  setDinersValidators(value){
    if (value&&this.productInfoFormGroup.get('dinersclub').value&&this.productName!=='VAS') {
      this.productInfoFormGroup.get('dinersclubnum').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
      this.productInfoFormGroup.get('dinersclubnum').updateValueAndValidity();
    } else {
      this.productInfoFormGroup.get('dinersclubnum').clearValidators();
      this.productInfoFormGroup.get('dinersclubnum').updateValueAndValidity();
    }
  }
  clearPrepaidValiidation(){
    if(!this.prepaidSolutions&&(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ')){
      this.productInfoFormGroup.get('airTime').clearValidators();
      this.productInfoFormGroup.get('airTime').updateValueAndValidity();
      this.productInfoFormGroup.get('instantMoney').clearValidators();
      this.productInfoFormGroup.get('instantMoney').updateValueAndValidity();
      this.productInfoFormGroup.get('electricity').clearValidators();
      this.productInfoFormGroup.get('electricity').updateValueAndValidity();
    }
  }
  setCounterTopValidators(value){
    if(this.productName==='AUTO_LINK'){
      if (value) {
        this.productInfoFormGroup.get('counterTopDeviceModel').setValidators([Validators.required]);
        this.productInfoFormGroup.get('counterTopDeviceModel').updateValueAndValidity();

        this.productInfoFormGroup.get('numCounterTopDevice').setValidators([Validators.required]);
        this.productInfoFormGroup.get('numCounterTopDevice').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('counterTopDeviceModel').clearValidators();
        this.productInfoFormGroup.get('counterTopDeviceModel').updateValueAndValidity();

        this.productInfoFormGroup.get('numCounterTopDevice').clearValidators();
        this.productInfoFormGroup.get('numCounterTopDevice').updateValueAndValidity();
      }
    }
  }
  setPortableValidators(value){
    if(this.productName==='AUTO_LINK'){
      if (value) {
        this.productInfoFormGroup.get('portableDeviceModel').setValidators([Validators.required]);
        this.productInfoFormGroup.get('portableDeviceModel').updateValueAndValidity();

        this.productInfoFormGroup.get('numPortableDevice').setValidators([Validators.required]);
        this.productInfoFormGroup.get('numPortableDevice').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('portableDeviceModel').clearValidators();
        this.productInfoFormGroup.get('portableDeviceModel').updateValueAndValidity();

        this.productInfoFormGroup.get('numPortableDevice').clearValidators();
        this.productInfoFormGroup.get('numPortableDevice').updateValueAndValidity();
      }
    }

  }
  setAmericanExpressValidators(value){
    if (value&&this.productName!=='VAS') {
      this.productInfoFormGroup.get('americanexpressnum').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
      this.productInfoFormGroup.get('americanexpressnum').updateValueAndValidity();
    } else {
      this.productInfoFormGroup.get('americanexpressnum').clearValidators();
      this.productInfoFormGroup.get('americanexpressnum').updateValueAndValidity();
    }
  }
  setRCSValidators(value){
    if(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ'){
      if (value) {
        this.productInfoFormGroup.get('rcsnum').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
        this.productInfoFormGroup.get('rcsnum').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('rcsnum').clearValidators();
        this.productInfoFormGroup.get('rcsnum').updateValueAndValidity();
      }
    }

  }

  setStannicFleetValidators(value){
    if(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ'){
      if (value) {
        this.productInfoFormGroup.get('stannicFleetNumber').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
        this.productInfoFormGroup.get('stannicFleetNumber').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('stannicFleetNumber').clearValidators();
        this.productInfoFormGroup.get('stannicFleetNumber').updateValueAndValidity();
      }
    }

  }

  setWesBankFleetValidators(value){
    if(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ'){
      if (value) {
        this.productInfoFormGroup.get('wesBankFleetNumber').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
        this.productInfoFormGroup.get('wesBankFleetNumber').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('wesBankFleetNumber').clearValidators();
        this.productInfoFormGroup.get('wesBankFleetNumber').updateValueAndValidity();
      }
    }

  }

  setAbsaFleetValidators(value){
    if(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ'){
      if (value) {
        this.productInfoFormGroup.get('absaFleetNumber').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
        this.productInfoFormGroup.get('absaFleetNumber').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('absaFleetNumber').clearValidators();
        this.productInfoFormGroup.get('absaFleetNumber').updateValueAndValidity();
      }
    }

  }
  setNedFleetValidators(value){
    if(this.productName==='AUTO_LINK'||this.productName==='POCKET_BIZ'){
      if (value) {
        this.productInfoFormGroup.get('nedFleetNumber').setValidators([Validators.required,Validators.pattern('^[0-9]*$')]);
        this.productInfoFormGroup.get('nedFleetNumber').updateValueAndValidity();
      } else {
        this.productInfoFormGroup.get('nedFleetNumber').clearValidators();
        this.productInfoFormGroup.get('nedFleetNumber').updateValueAndValidity();
      }
    }

  }

  setProductOptionsValidators1(value){
    if ((value==='one'||value==='two')&&this.productName==='SIMPLY_BLU') {
      this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators;
      this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
      this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
      this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
    } else if((value==='three'||value==='four')&&this.productName==='SIMPLY_BLU'){
      this.productInfoFormGroup.get('merchantWebsiteAddress').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
      this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
      this.productInfoFormGroup.get('simplyBluPaymentGateway').setValidators([Validators.required]);
      this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
    }
    else if(this.productName==='MASTER_PASS'||this.productName==='RECURRING_PAYMENTS'||this.productName==='ECOMMERCE'){
      this.productInfoFormGroup.get('merchantWebsiteAddress').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
      this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
      this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
      this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
    }
    else {
      this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
      this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
      this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
      this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();

    }
  }

  setProductNameValidators1(value){
    switch (value) {
      case 'MASTER_PASS':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
          this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();

        this.productInfoFormGroup.get('paymentGateway').setValidators([Validators.required]);
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('merchantWebsiteAddress').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
        this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
        break;
      case 'RECURRING_PAYMENTS':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();

        this.productInfoFormGroup.get('recurringPaymentGateway').setValidators([Validators.required]);
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('merchantWebsiteAddress').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
        this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
        break;
      case 'ECOMMERCE':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();

        this.productInfoFormGroup.get('paymentMethod').setValidators([Validators.required]);
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').setValidators([Validators.required]);
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('merchantWebsiteAddress').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
        this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
        break;
      case 'SIMPLY_BLU':
        this.productInfoFormGroup.get('productOption').setValidators([Validators.required]);
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        break;
      case 'ANDROID_SOFTPOS':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').setValidators([Validators.required]);
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').setValidators([Validators.required]);
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        break;
      case 'AUTO_LINK':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
          this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
          this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
          this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').setValidators([Validators.required]);
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').setValidators([Validators.required]);
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').setValidators([Validators.required]);
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
        break;
      case 'POCKET_BIZ':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
          this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
          this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
          this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').setValidators([Validators.required]);
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').setValidators([Validators.required]);
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').setValidators([Validators.required]);
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').setValidators([Validators.required]);
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
        break;

      case 'ELECTRONIC_BILL':
        this.productInfoFormGroup.get('productOption').clearValidators();
        this.productInfoFormGroup.get('productOption').updateValueAndValidity();
        this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
          this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
          this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').setValidators([Validators.required]);
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        break;
        case 'VAS':
          this.productInfoFormGroup.get('productOption').clearValidators();
          this.productInfoFormGroup.get('productOption').updateValueAndValidity();
          this.productInfoFormGroup.get('americanexpressnum').clearValidators();
          this.productInfoFormGroup.get('americanexpressnum').updateValueAndValidity();
          this.productInfoFormGroup.get('dinersclubnum').clearValidators();
          this.productInfoFormGroup.get('dinersclubnum').updateValueAndValidity();
          this.productInfoFormGroup.get('merchantWebsiteAddress').clearValidators();
          this.productInfoFormGroup.get('merchantWebsiteAddress').updateValueAndValidity();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').clearValidators();
          this.productInfoFormGroup.get('simplyBluPaymentGateway').updateValueAndValidity();
          this.productInfoFormGroup.get('paymentMethod').clearValidators();
        this.productInfoFormGroup.get('paymentMethod').updateValueAndValidity();
        this.productInfoFormGroup.get('paymentGateway').clearValidators();
        this.productInfoFormGroup.get('paymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('recurringPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('recurringPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').clearValidators();
        this.productInfoFormGroup.get('electronicBillPaymentGateway').updateValueAndValidity();
        this.productInfoFormGroup.get('bankingTime').clearValidators;
        this.productInfoFormGroup.get('bankingTime').updateValueAndValidity();
        this.productInfoFormGroup.get('billingCycle').clearValidators;
        this.productInfoFormGroup.get('billingCycle').updateValueAndValidity();
        this.productInfoFormGroup.get('numDevices').clearValidators;
        this.productInfoFormGroup.get('numDevices').updateValueAndValidity();
        this.productInfoFormGroup.get('whenDevReq').clearValidators;
        this.productInfoFormGroup.get('whenDevReq').updateValueAndValidity();
          break;
    }
  }


  setChoiceOfDomainsValidators(value){
    if (value==='two'&&(this.productOption==='one'||this.productOption==='two')&&this.productName==='SIMPLY_BLU') {
      this.productInfoFormGroup.get('domainName').setValidators([Validators.required,Validators.pattern('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]);
      this.productInfoFormGroup.get('domainName').updateValueAndValidity();
    } else {
      this.productInfoFormGroup.get('domainName').clearValidators();
      this.productInfoFormGroup.get('domainName').updateValueAndValidity();
    }
  }
  openDeviceDialog() {
    const dialogRef = this.deviceDialog.open(DevicePickerDialogComponent, {
      width: '580px', height: '420px'
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
