import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {Angulartics2UirouterModule} from 'angulartics2/uiroutermodule'
import { NavigatorProductsComponent } from './components/navigator-products/navigator-products.component';
import {SimplybluComponent} from './components/simplyblu/simplyblu.component';
import { ZmbiziComponent } from './components/zmbizi/zmbizi.component';
import { ZmbiziGuard } from './services/zmbizi-guard';


const routes: Routes = [
  { path: 'simplyblu', component: SimplybluComponent },
{ path: 'autoLink', component: NavigatorProductsComponent },
{ path: 'pocketBiz', component: NavigatorProductsComponent },
{ path: 'masterPass', component: NavigatorProductsComponent },
 { path: 'simplyBlu', component: NavigatorProductsComponent },
{ path: 'ecommerce', component: NavigatorProductsComponent },
{ path: 'vas', component: NavigatorProductsComponent },
{ path: 'recurringPayments', component: NavigatorProductsComponent },
{ path: 'electronicBill', component: NavigatorProductsComponent },
{ path: 'zmbizi', component: ZmbiziComponent, canActivate: [ZmbiziGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',} // Add options right here
  ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
