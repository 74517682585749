import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import { BusinessInfoComponent } from '../business-info/business-info.component';

@Component({
  selector: 'app-business-location',
  templateUrl: './business-location.component.html',
  styleUrls: ['./business-location.component.css']
})
export class BusinessLocationComponent implements OnInit,OnChanges {
  // @ts-ignore
  @ViewChild(BusinessInfoComponent) businessInfo: BusinessInfoComponent;
  businessLocationFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder,private navigatorLookupService: NavigatorLookupService) { }
  provinces: any = this.navigatorLookupService.provinces;
  countries: any = this.navigatorLookupService.countries;
  
  @Input() inputData:any={};
  @Input() businessType: string;
  @Output() outPutData = new EventEmitter<any>();

  ngOnInit() {
    this.businessLocationFormGroup = this.formBuilder.group({
      registeredAddressCheck: [true],
      postalAddressCheck: [true],
      //Physical Address
      buildingName: [''],
      street: ['', Validators.required],
      suburb: ['', Validators.required],
      city: ['', Validators.required],
      province: ['', Validators.required],
      postalCode: ['', [Validators.required,Validators.pattern('[0-9]{4}')]],
      country: ['', Validators.required],

      //Registered Address
      registeredBuildingName: [''],
      registeredStreet: [''],
      registeredSuburb: [''],
      registeredCity: [''],
      registeredProvince: [''],
      registeredPostalCode: [''],
      registeredCountry: [''],

      //Postal Address
      postalBuildingName: [''],
      postalStreet: [''],
      postalSuburb: [''],
      postalCity: [''],
      postalProvince: [''],
      postalPostalCode: [''],
      postalCountry: [''],
      postalPOBox:[''],

      businessTelephoneNumber: ['', [Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(10),Validators.maxLength(10)]],
      businessEmailAddress: ['', [Validators.required,Validators.email]],
      primaryContactPerson: ['', Validators.required],
      primaryContactNumber: ['', [Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(10),Validators.maxLength(10)]],
      businessTradingHours:['']
    });
    this.businessLocationFormGroup.patchValue(this.inputData);
    this.businessLocationFormGroup.valueChanges.subscribe(selectedValue  => {
      this.outPutData.emit(JSON.stringify(this.businessLocationFormGroup.value));
    });

    this.businessLocationFormGroup.get('postalAddressCheck').valueChanges.subscribe(value => {
      this.setPostalAddressValidators(value);
      }
    );

    this.businessLocationFormGroup.get('registeredAddressCheck').valueChanges.subscribe(value => {
        this.setRegisteredAddressValidators(value);
      }
    );
  }

  get businessInfoComponent() {
    return this.businessInfo ? this.businessInfo.businessInfoFormGroup : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.businessLocationFormGroup){
      this.businessLocationFormGroup.patchValue(this.inputData);
    }
    if(this.businessLocationFormGroup && this.businessLocationFormGroup.controls['postalStreet'].valid){
      this.businessLocationFormGroup.get('postalPOBox').clearValidators();
      this.businessLocationFormGroup.get('postalPOBox').updateValueAndValidity();
    }
    if(this.businessLocationFormGroup && this.businessLocationFormGroup.controls['postalPOBox'].valid){
      this.businessLocationFormGroup.get('postalStreet').clearValidators();
      this.businessLocationFormGroup.get('postalStreet').updateValueAndValidity();
    }
  }

  setRegisteredAddressValidators(value){
    if (!value) {
      this.businessLocationFormGroup.get('registeredStreet').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('registeredStreet').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredSuburb').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('registeredSuburb').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredCity').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('registeredCity').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredProvince').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('registeredProvince').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredPostalCode').setValidators([Validators.required,Validators.pattern('[0-9]{4}')]);
      this.businessLocationFormGroup.get('registeredPostalCode').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredCountry').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('registeredCountry').updateValueAndValidity();
    } else {
      this.businessLocationFormGroup.get('registeredStreet').clearValidators();
      this.businessLocationFormGroup.get('registeredStreet').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredSuburb').clearValidators();
      this.businessLocationFormGroup.get('registeredSuburb').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredCity').clearValidators();
      this.businessLocationFormGroup.get('registeredCity').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredProvince').clearValidators();
      this.businessLocationFormGroup.get('registeredProvince').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredPostalCode').clearValidators();
      this.businessLocationFormGroup.get('registeredPostalCode').updateValueAndValidity();

      this.businessLocationFormGroup.get('registeredCountry').clearValidators();
      this.businessLocationFormGroup.get('registeredCountry').updateValueAndValidity();
    }
  }

  setPostalAddressValidators(value){
    if (!value) {
      this.businessLocationFormGroup.get('postalStreet').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalStreet').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalSuburb').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalSuburb').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalCity').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalCity').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalProvince').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalProvince').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalPostalCode').setValidators([Validators.required,Validators.pattern('[0-9]{4}')]);
      this.businessLocationFormGroup.get('postalPostalCode').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalPOBox').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalPOBox').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalCountry').setValidators([Validators.required]);
      this.businessLocationFormGroup.get('postalCountry').updateValueAndValidity();
    } else {
      this.businessLocationFormGroup.get('postalStreet').clearValidators();
      this.businessLocationFormGroup.get('postalStreet').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalSuburb').clearValidators();
      this.businessLocationFormGroup.get('postalSuburb').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalCity').clearValidators();
      this.businessLocationFormGroup.get('postalCity').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalProvince').clearValidators();
      this.businessLocationFormGroup.get('postalProvince').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalPostalCode').clearValidators();
      this.businessLocationFormGroup.get('postalPostalCode').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalPOBox').clearValidators();
      this.businessLocationFormGroup.get('postalPOBox').updateValueAndValidity();

      this.businessLocationFormGroup.get('postalCountry').clearValidators();
      this.businessLocationFormGroup.get('postalCountry').updateValueAndValidity();
    }
  }

  get registeredAddressCheck(): any {
    return this.businessLocationFormGroup.get('registeredAddressCheck').value;
  }

  get postalAddressCheck(): any {
    return this.businessLocationFormGroup.get('postalAddressCheck').value;
  }

  onPostalAddressCheckChange($event){
    this.setPostalAddressValidators($event.checked);
  }

  onRegisteredAddressCheckChange($event){
    this.setRegisteredAddressValidators($event.checked);
  }
}