import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { environment } from 'src/environments/environment';
import {NavigatorLookupService} from '../navigator-lookup.service';
@Component({
  selector: 'app-sales-confirmation',
  templateUrl: './sales-confirmation.component.html',
  styleUrls: ['./sales-confirmation.component.css']
})
export class SalesConfirmationComponent implements OnInit {

  mcCodeList: string[] = this.navigatorLookupService.mcCodeList;
  salesConfirmationFormGroup: FormGroup;
  zmbizi = false;
  constructor(private formBuilder: FormBuilder,private navigatorLookupService: NavigatorLookupService, private window: Window) { 
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
    }
  }
  

  ngOnInit() {
    this.salesConfirmationFormGroup = this.formBuilder.group({
      mcCode: ['', Validators.required],
      // consent1:[false,Validators.requiredTrue],
      // consent2:[false,Validators.requiredTrue],
      // consent3:[false,Validators.requiredTrue]
    });
  }

}
