import { Component, Inject } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {SbgMenuIcon, SbgShowHeaderMenu} from '@sbg/components/sbg-header';
import {ActivatedRoute, NavigationStart, Router, RouterEvent} from '@angular/router'
import {DtmAnalyticsServiceLaunch} from './dtm-analytics-service-launch'
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';
declare var Msal;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbCarouselConfig]
})

export class AppComponent {
  title = 'Merchant Console';
  token:string=sessionStorage.getItem('msal.idtoken');
  navLinks = [
    {path: '', label: 'HOME', analytics: 'home'},
    {path: 'solutions', label: 'SOLUTIONS', analytics: 'solutions'},
    {path: 'simplyblu', label: 'LETS GET STARTED!', analytics: 'solutions'},
    {path: 'insights', label: 'INSIGHTS', analytics: 'insights'},
    {path: 'help', label: 'HELP', analytics: 'help'},
    {path: 'knowledgecentre', label: 'KNOWLEDGE CENTRE', analytics: 'knowledge centre'}
    ];

  zmbizi = false;

  constructor(
    private window: Window,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private route:ActivatedRoute,
    private dtm:DtmAnalyticsServiceLaunch,
    config: NgbCarouselConfig) {

    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/logo.svg'));

    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/logo.svg'));

    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart)
      {
        console.log('MOVED PAGES'+ ' to ' + event.url)
         const path = event.url.substring(1);
        let analyticslabel = '';

        for (const link of this.navLinks)
        {
          if (path === link.path)
          {
            analyticslabel = link.analytics
            this.dtm.updatePageName(analyticslabel)
          }
        }
      }
    })

    config.interval = 6000;
    config.keyboard = true;
    config.pauseOnHover = true;
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
      this.router.navigate(['/zmbizi']);
    }


  }

  sbgShowHeaderMenu = SbgShowHeaderMenu;
  iconType = SbgMenuIcon.HAMBURGER;

  changeMenuIcon($event) {
    this.iconType = $event.checked ? SbgMenuIcon.BACKARROW : SbgMenuIcon.HAMBURGER;
  }
  salesLogin() {
    const azureConfig = {
      clientID: environment.clientID,
      authority: environment.authority,
      b2cScopes: environment.b2cScopes,
      webApi: environment.webApi
    };
    const azureApplication = new Msal.UserAgentApplication(azureConfig.clientID, azureConfig.authority, function (errorDesc, token, error, tokenType) {
      // Called after loginRedirect or acquireTokenPopup
    });
    azureApplication.loginPopup(azureConfig.b2cScopes).then(function (idToken1) {
      azureApplication.acquireTokenSilent(azureConfig.b2cScopes).then(function (accessToken1) {
        sessionStorage.setItem('msal.idtoken', accessToken1);
        sessionStorage.setItem('usertype', 'sales');
        window.location.reload();
        }
      );
    }, function (error) {
      if (error.indexOf('AADB2C90118') > -1) {
        return false;
      }
      return true;
    });
  }
  ngOnDestroy() {
   // this.sub.unsubscribe();
  }
}

