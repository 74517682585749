import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SbgButtonModule} from '@sbg/components/sbg-button/';
import {SbgHeaderModule} from '@sbg/components/sbg-header/';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SbgIconModule} from '@sbg/components/sbg-icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { SolutionsComponent } from './components/solutions/solutions.component';
import { InsightsComponent } from './components/insights/insights.component';
import { HelpComponent } from './components/help/help.component';
import { KnowledgecentreComponent } from './components/knowledgecentre/knowledgecentre.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import { DropdownModule } from 'angular-bootstrap-md';
import {MatVideoModule} from 'mat-video';
import {MatDatepickerModule,MatNativeDateModule,MatDialog, MatDialogModule, MatExpansionModule,MatSnackBarModule} from '@angular/material';
import { YoutubeModalComponent } from './components/youtube-modal/youtube-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtmAnalyticsServiceLaunch} from './dtm-analytics-service-launch'
import {AppHttpInterceptor} from './services/http.interceptor';
import { SimplybluComponent } from './components/simplyblu/simplyblu.component'
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';

import { BusinessLocationComponent } from './components/navigator/business-location/business-location.component';
import { ProductInfoComponent } from './components/navigator/product-info/product-info.component';
import { BankingDetailsComponent } from './components/navigator/banking-details/banking-details.component';
import { PricingStructureComponent } from './components/navigator/pricing-structure/pricing-structure.component';
import { BusinessInfoComponent } from './components/navigator/business-info/business-info.component';
import { BusinessOwnersComponent } from './components/navigator/business-owners/business-owners.component';
import { SupportingDocumentsComponent } from './components/navigator/supporting-documents/supporting-documents.component';
import { ExistingCustomerComponent } from './components/navigator/existing-customer/existing-customer.component';
import { ConfirmationDialogComponent } from './components/navigator/confirmation-dialog/confirmation-dialog.component';
import {HTTPListener} from './services/http-status/http-listener';
import {HTTPStatus} from './services/http-status/http-status.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NavigatorProductsComponent } from './components/navigator-products/navigator-products.component';
import { DevicePickerDialogComponent } from './components/navigator/device-picker-dialog/device-picker-dialog.component';
import { SalesConfirmationComponent } from './components/navigator/sales-confirmation/sales-confirmation.component';
import { ZmbiziComponent } from './components/zmbizi/zmbizi.component';
import { ZmbiziGuard } from './services/zmbizi-guard';

const RxJS_Services = [HTTPListener, HTTPStatus];
const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'insights', component: InsightsComponent},
  { path: 'help', component: HelpComponent },
  { path: 'knowledgecentre', component: KnowledgecentreComponent },
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SolutionsComponent,
    InsightsComponent,
    HelpComponent,
    KnowledgecentreComponent,
    AppComponent,
    YoutubeModalComponent,
    SimplybluComponent,
    BusinessLocationComponent,
    ProductInfoComponent,
    BankingDetailsComponent,
    PricingStructureComponent,
    BusinessInfoComponent,
    BusinessOwnersComponent,
    SupportingDocumentsComponent,
    ExistingCustomerComponent,
    ConfirmationDialogComponent,
    NavigatorProductsComponent,
    DevicePickerDialogComponent,
    SalesConfirmationComponent,
    ZmbiziComponent,
  ],
  exports:
  [
    BrowserModule,
    AppRoutingModule,
    SbgButtonModule,
    SbgHeaderModule,
    MatGridListModule,
    MatInputModule,
    SbgIconModule,
    MatIconModule,
    HttpClientModule,
    MatTabsModule,
    MatVideoModule,
    BrowserAnimationsModule,
    YoutubeModalComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    SbgButtonModule,
    SbgHeaderModule,
    MatGridListModule,
    MatInputModule,
    SbgIconModule,
    MatIconModule,
    HttpClientModule,
    MatTabsModule,
    MatVideoModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    DropdownModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: true,
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules,
        onSameUrlNavigation: 'reload',
      } // <-- debugging purposes only
    ),
    MatCardModule,
    MatInputModule,
    MatExpansionModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    SbgButtonModule, 
    DtmAnalyticsServiceLaunch, 
    {provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true},
    RxJS_Services,
    {provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true}, 
    {provide: Window, useValue: window},
    ZmbiziGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  entryComponents: [YoutubeModalComponent,ConfirmationDialogComponent,DevicePickerDialogComponent],
})
export class AppModule {
  private static AppHttpInterceptor: any
}
