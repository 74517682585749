import { Injectable }     from '@angular/core';
import { CanActivate }    from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class ZmbiziGuard implements CanActivate {
    canActivate() {
        //Your redirect logic/condition. I use this.
        if (this.window.location.hostname === `${environment.zmbiziURL}`){
            console.log("Can Activate");
            return true
        }
        console.log("Cannot Activate");
        return false;
    }
    //Constructor 
    constructor(private router: Router, private window: Window,) { }
}