import { Injectable } from '@angular/core';
declare var _satellite;
declare var dataLayer;
@Injectable({
  providedIn: 'root'
})
export class DtmAnalyticsServiceLaunch {
  previousStep: string;

  constructor() {
    this.previousStep = "";
   }

  getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  updatePageName(pageLabel){
    const pageName = pageLabel;

    const currentPathSplit = pageLabel.split(':');
    console.log("subsections: ", currentPathSplit);
    dataLayer.pageCategory = currentPathSplit[0];
    dataLayer.pageName = pageName;

    dataLayer.pageCategory = currentPathSplit[0];
    dataLayer.pageSubSection1 = currentPathSplit.length > 1
      ? currentPathSplit[0] + ':' + currentPathSplit[1] : '';
    dataLayer.pageSubSection2 = currentPathSplit.length > 2
      ? currentPathSplit[0] + ':' + currentPathSplit[1] + ':' + currentPathSplit[2] : '';
    dataLayer.pageSubSection3 = currentPathSplit.length > 3
      ? currentPathSplit[0] + ':' + currentPathSplit[1] + ':' + currentPathSplit[2] + ':' + currentPathSplit[3] : '';
    dataLayer.pageSubSection4 = currentPathSplit.length > 4
      ? currentPathSplit[0] + ':' + currentPathSplit[1] + ':' + currentPathSplit[2] + ':' +
      currentPathSplit[3] + ':' + currentPathSplit[4] : '';

    dataLayer.deviceType = this.getDeviceType();
    _satellite.setVar('JS_deviceType', dataLayer.deviceType);
    _satellite.track('globalVirtualPageView');
  }

  simplybluClick(dataIntent, dataScope, dataText, prevStep){
    // Caters for clicks in the final step (confirmation page) which have pipes in the datatext for the sub component
    //datatext is current step to note the current page client is viewing
    if(dataText.includes("|")){
        dataLayer.userInteraction = dataIntent +" | "+ dataScope +" | "+ this.previousStep;
    }
    else{
        dataLayer.userInteraction = dataIntent +" | "+ dataScope +" | "+ prevStep;
        // Persist previous step for confirmation page
        this.previousStep = prevStep;
    }
    console.log("LinkName: ", dataLayer.linkName);
    _satellite.setVar('JV_Link_Name_Generic_User_Interaction', dataLayer.userInteraction);
    _satellite.track('genericUserInteraction');
  }

  confirmationPageClick(dataIntent, dataScope, dataText, prevStep, index){
    //datatext is current step to note the current page client is viewing
    const step = index + 1;
        dataLayer.userInteraction = dataIntent +" | "+ dataScope +" | "+ dataText.substring(dataText.indexOf("|") + 2);
        console.log("LinkName: ", dataLayer.linkName);
        _satellite.setVar('JV_Link_Name_Generic_User_Interaction', dataLayer.userInteraction);
        _satellite.track('genericUserInteraction');
        this.updatePageName("simplyblu:" + dataScope + ":" + "Step " + step + " " + dataText.substring(0, dataText.indexOf("|")));
    }

  clickEvent(INTERACTION_TYPE, DATA_ID, DATA_TEXT){
    const userInteractionText = INTERACTION_TYPE+'_'+DATA_ID + ' | ' + DATA_TEXT
    dataLayer.userInteraction = userInteractionText
    _satellite.track('genericUserInteraction');
  }

  sendSiteError(errorCode, request, message){
    dataLayer.siteErrorCode = errorCode+' | ' + request + ' | ' + message
    _satellite.track('sendSiteErrorCode');
  }
  logToConsole(LOG_TEXT){
    console.log(LOG_TEXT);
  }

}
