export class NavigatorNotification {
    message: string;
    action: string;
    duration: number;

    constructor(message: string, action?: string, duration?: number) {
        this.message = message;
        this.action = action;
        this.duration = duration;
    }
}
