import {Component, OnInit} from '@angular/core';
import {YoutubeModalComponent} from '../youtube-modal/youtube-modal.component';
import {MatDialog} from '@angular/material';
import {DtmAnalyticsServiceLaunch} from '../../dtm-analytics-service-launch'

@Component({
  selector: 'app-knowledgecentre',
  templateUrl: './knowledgecentre.component.html',
  styleUrls: ['./knowledgecentre.component.css']
})
export class KnowledgecentreComponent implements OnInit {
  constructor(public dialog: MatDialog, private dtm:DtmAnalyticsServiceLaunch) {}

  openDialog(Title: string, url: string): void {
    const dialogRef = this.dialog.open(YoutubeModalComponent, {
      width: '50%',
      height: '45%',
      data: {youtubeID: url, title: Title}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  ngOnInit() {
  }
}



