import { Component, OnInit } from '@angular/core';
import { PostService } from '../../services/post.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms'
import {DtmAnalyticsServiceLaunch} from '../../dtm-analytics-service-launch'
import {Router} from '@angular/router'


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit {

  destEmail = 'merchantsupport@standardbank.co.za'
  subjectPrefix = '[TEST]'
  constructor(private service: PostService, private fb: FormBuilder,
              private dtm:DtmAnalyticsServiceLaunch,
              private router: Router) { this.createForm(); this.createDirectEmailForm();}
  emailForm: FormGroup;
  emailDirectForm: FormGroup;

  submitted = false;

  ngOnInit() {
    if(window.location.hostname.toUpperCase() === 'MERCHANTCONSOLE.STANDARDBANK.CO.ZA'){
      this.destEmail = 'merchantsupport@standardbank.co.za'
      this.subjectPrefix = ''
    }
    else{
      this.destEmail = 'neelesh.rambally@standardbank.co.za'
      this.subjectPrefix = '[TEST]' + window.location.hostname.toUpperCase() + ' '
    }
  }
  createForm() {
    this.emailForm = this.fb.group({
      name: ['', Validators.required ],
      contact: ['', Validators.required ],
      email: ['', [Validators.required, Validators.email] ],
      queryType: ['', Validators.required ],
      merchantNumber: ['' ],
      message: ['', Validators.required ]
    });
  }
  onSubmit(name: HTMLInputElement,
           contact: HTMLInputElement,
           email: HTMLInputElement,
           queryType: HTMLInputElement,
           merchantNumber: HTMLInputElement,
           message: HTMLInputElement) {
    const post = {
      merchNo: merchantNumber.value,
      fromEmail: email.value,
      destEmail: this.destEmail,
      subjectLine: this.subjectPrefix + 'Merchant Console help query: ' + merchantNumber.value,
      clientName: name.value,
      clientReplyEmail: 'merchantsupport@standardbank.co.za',
      clientTelephone: contact.value,
      clientMessageText: 'Query Type: ' + queryType.value + ' | \n' + 'Message: ' +  message.value
    };

    this.service.create(post)
      .subscribe((response: { id }) => {
       // post[] = response.id;
       // this.posts.splice(0, 0, post);
      });
    this.emailForm.reset()

    }

  createDirectEmailForm() {
    this.emailDirectForm = this.fb.group({
      justName: ['', Validators.required ],
    });
  };

  onEmailSubmit(justName: HTMLInputElement) {

    window.location.href = `mailto:merchantsupport@standardbank.co.za?subject=Support request for Merchant: ${justName.value}`;

    this.emailDirectForm.reset()
  }
}
