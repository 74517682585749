import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-existing-customer',
  templateUrl: './existing-customer.component.html',
  styleUrls: ['./existing-customer.component.css']
})
export class ExistingCustomerComponent implements OnInit,OnChanges {
  @Input() inputData:any;
  @Input() existing:boolean;
  @Output() outPutData = new EventEmitter<any>();
  @Output() customerType=new EventEmitter<boolean>();
  public existingCustomerFormGroup: FormGroup;
  zmbizi = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private window: Window) {
    if (this.window.location.hostname === `${environment.zmbiziURL}`){
      this.zmbizi = true;
      this.router.navigate(['/zmbizi']);
    }
   }

  
  
  ngOnInit() {
    this.existingCustomerFormGroup = this.formBuilder.group({
      //referenceNumber:['',[Validators.required]],
      //merchantEmailAddress:['',[Validators.required,Validators.email]],
      referenceNumber:[''],
      merchantEmailAddress:[''],
      existingCustomer: [false],
      salesConsultant:[false]
    });
    this.setExistingValidators(this.existing);
    this.existingCustomerFormGroup.patchValue(this.inputData);
    this.existingCustomerFormGroup.valueChanges.subscribe(value  => {
      this.outPutData.emit(JSON.stringify(this.existingCustomerFormGroup.value));
    });
    this.existingCustomerFormGroup.get('existingCustomer').valueChanges
      .subscribe(value => {
        this.customerType.emit(this.existingCustomerFormGroup.get('existingCustomer').value);
        }
      );
  }
  ngOnChanges(changes: SimpleChanges) {
    // if(changes['existing']){
    //   this.setExistingValidators(this.existing);
    // }
    if(this.existingCustomerFormGroup){
      this.existingCustomerFormGroup.patchValue(this.inputData);
    }
  }
  setExistingValidators(value){
    if (value) {
      this.existingCustomerFormGroup.get('referenceNumber').setValidators([Validators.required]);
      this.existingCustomerFormGroup.get('referenceNumber').updateValueAndValidity();
      this.existingCustomerFormGroup.get('merchantEmailAddress').setValidators([Validators.required,Validators.email]);
      this.existingCustomerFormGroup.get('merchantEmailAddress').updateValueAndValidity();
    } else {
      this.existingCustomerFormGroup.get('referenceNumber').clearValidators();
      this.existingCustomerFormGroup.get('referenceNumber').updateValueAndValidity();
      this.existingCustomerFormGroup.get('merchantEmailAddress').clearValidators();
      this.existingCustomerFormGroup.get('merchantEmailAddress').updateValueAndValidity();
    }
  }
}
