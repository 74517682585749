import { Component, OnInit } from '@angular/core';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tiles: Tile[] = [
    {text: 'One', cols: 4, rows: 30, color: 'lightblue'},
    {text: 'Two', cols: 4, rows: 4, color: 'lightgreen'},
    {text: 'Three', cols: 4, rows: 10, color: 'lightpink'},
    {text: 'Four', cols: 4, rows: 1, color: '#DDBDF1'},
  ];

  navLinks = [
    {path: 'solutions_POS', label: 'STAND-ALONE POINT OF SALE (POS) SOLUTIONS'},
    {path: 'solutions_online_payment', label: 'ONLINE PAYMENT SOLUTIONS'},
    {path: 'solutions_value_adds', label: 'VALUE ADDED PRODUCTS AND SERVICES'},

  ];
  constructor() { }

  ngOnInit() {
  }


}
