// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  callMeBackEmailTo: 'simplyblu@standardbank.co.za',
  //  baseUrl: 'https://merchantonboarding-sit.apps.tc001745-dev.afs1-nprd.aws-za.sbgrp.cloud/navigator',
  // baseUrl: 'https://merchantonboarding-sit.apps.ocp4sdc.ose.standardbank.co.za/navigator',
  //  branchUrl: 'https://branch-lookup-sit.apps.ocp4rdc.ose.standardbank.co.za/bank',
  baseUrl:'/navigator',
  // baseUrl: 'http://localhost:8080/navigator',
  branchUrl:'/bank',
  clientID: 'd6da953c-f826-4cee-a056-25ca092eb8e6',
  authority: 'https://login.microsoftonline.com/7369e6ec-faa6-42fa-bc0e-4f332da5b1db/',
  b2cScopes: ['d6da953c-f826-4cee-a056-25ca092eb8e6'],
  webApi: 'https://merchantconsolesit.standardbank.co.za/simplyBlu',
  zmbiziURL: 'zmbizi.standardbank.co.za'
  // zmbiziURL:'localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
