import {Component, NgModule, OnInit} from '@angular/core';
import {DtmAnalyticsServiceLaunch} from '../../dtm-analytics-service-launch'
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})

@NgModule({
  imports: [
  ]
})

export class SolutionsComponent implements OnInit {
  public href: string = "";
  solutionHeader = true;

  constructor(
    private dtm:DtmAnalyticsServiceLaunch,
  ) {

  }

  ngOnInit() {
    this.getitNowURL();
  }

  onSlide(slideEvent: NgbSlideEvent){
    console.log(slideEvent.current);
    if (slideEvent.current == "ngb-slide-0"){
      this.solutionHeader = true;
    }else{
      this.solutionHeader = false;
    }
  }
  getitNowURL(): string{
    this.href = window.location.href;
    if(!this.href.includes("merchantconsolesit") && !this.href.includes("localhost")){
     //! NB: android POS not yet added, so path based url is not used for it
      return "https://merchantconsole.standardbank.co.za";
    }else{
      return "https://merchantconsolesit.standardbank.co.za"
    }
  }
}
