import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  private url = '/email';

  constructor(private httpClient: HttpClient) { }

  create(post) {
    return this.httpClient.post(this.url, JSON.stringify(post));
  }

}
