import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigatorLookupService} from '../navigator-lookup.service';
import {Observable} from 'rxjs';
@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.css']
})
export class BusinessInfoComponent implements OnInit, OnChanges {
  businessInfoFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private navigatorLookupService: NavigatorLookupService) {
  }

  ownerNumbers: any = this.navigatorLookupService.ownerNumbers;
  numVASDevices:any=this.navigatorLookupService.numVASDevices;
  businessTypes: any = this.navigatorLookupService.businessTypes;
  merchantIndustries: any = this.navigatorLookupService.merchantIndustries;
  shareHolderNumbers: any = this.navigatorLookupService.shareHolderNumbers;
  formData: any = {};
  @Output() passNumberOfOwners = new EventEmitter<number>();
  @Output() passBusinessType = new EventEmitter<string>();
  @Input() inputData: any = {};
  @Input() product:string;
  @Output() outPutData = new EventEmitter<any>();
  filteredOptions: Observable<MerchantIndustry[]>;
  myControl = new FormControl('', Validators.required);
  ngOnInit() {
    this.businessInfoFormGroup = this.formBuilder.group({
      businessType: ['', Validators.required],
      businessTradingName: ['', Validators.required],
      businessRegName: [''],
      businessRegNumber: [''],
      businessVATNumber: [''],
      merchantNumber:[''],
      legalEntityName:[''],
      businessNature: ['', Validators.required],
      merchantIndustry: ['', Validators.required],
      annualTurnOver: ['', [Validators.required, Validators.pattern('^[0-9.]*$')]],
      numberOfOwners: [''],
      numberOfShareholders: [''],
      numVASDevices:[''],
      shares: new FormArray([]),
      devices:new FormArray([])
    });
    this.businessInfoFormGroup.patchValue(this.inputData);
    this.product!=='VAS'?this.setBusinessTypeValidator(this.businessInfoFormGroup.get('businessType').value):this.clearVASValidator;
    this.formData = this.inputData;
    if (Object.keys(this.formData).length) {
       this.passNumberOfOwners.emit(this.formData.numberOfOwners);
       this.passBusinessType.emit(this.formData.businessType);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.formData.shares.length; i++) {
        if(this.formData.shares !== undefined){
          this.s.push(this.formBuilder.group({
            share: new FormControl(this.formData.shares[i].share, [Validators.required, Validators.pattern('^[0-9.]*$'), Validators.min(1), Validators.max(100)]),
            shareHolderName: new FormControl(this.formData.shares[i].shareHolderName, [Validators.required]),
            shareHolderID: new FormControl(this.formData.shares[i].shareHolderID, [Validators.required, Validators.pattern('(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))')])
          }));
       }
      }
      for (let i = 0; i < this.formData.devices.length; i++) {
        this.d.push(this.formBuilder.group({
          terminal: new FormControl(this.formData.devices[i].terminal, [Validators.required]),
        }));
      }
    }
    this.businessInfoFormGroup.valueChanges.subscribe(selectedValue => {
      this.outPutData.emit(JSON.stringify(this.businessInfoFormGroup.value));
    });
    this.businessInfoFormGroup.get('businessType').valueChanges
      .subscribe(value => {
        if(this.product!=='VAS'){
          this.setBusinessTypeValidator(value);
        }

        }
      );
  }
  displayFn(industry: MerchantIndustry): string {
    return industry && industry.name ? industry.code +' '+industry.name : '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.businessInfoFormGroup) {
      this.businessInfoFormGroup.patchValue(this.inputData);
      const numberOfShareHolders = this.businessInfoFormGroup.get('numberOfShareholders').value;
      if (this.s.length < numberOfShareHolders) {
        for (let i = this.s.length; i < numberOfShareHolders; i++) {
          const shareData = this.inputData.shares && this.inputData.shares[i];
          if (shareData) {
            const share = new FormControl(shareData.share, [Validators.required, Validators.pattern('^[0-9.]*$'), Validators.min(1), Validators.max(100)]);
            const shareHolderName = new FormControl(shareData.shareHolderName, [Validators.required]);
            const shareHolderID = new FormControl(shareData.shareHolderID, [Validators.required, Validators.pattern('(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))')])
            this.s.push(this.formBuilder.group({ share, shareHolderName, shareHolderID }));
          }
        }
      } else {
        for (let i = this.s.length; i >= numberOfShareHolders; i--) {
          this.s.removeAt(i);
        }
      }
      this.onChangeNumVasDevices();
      if(changes['product']){
        this.clearVASValidator();
      }
    }
    if (this.businessInfoFormGroup && this.sharesRequired()) {
      const shares = this.businessInfoFormGroup.controls['shares'].value;
      const sumOfShares = shares.reduce((sum, share) => {
        if (share && share.share !== undefined && share.share !== null) {
          return sum + parseInt(share.share, 10);
        }
        return sum;
      }, 0);
      if (sumOfShares !== 100) {
        this.businessInfoFormGroup.controls['numberOfShareholders'].setErrors({ 'incorrect': true });
      } else {
        this.businessInfoFormGroup.controls['numberOfShareholders'].setErrors(null);
      }
    }
    else if(this.businessInfoFormGroup){
      this.businessInfoFormGroup.controls['numberOfShareholders'].setErrors(null);
      this.businessInfoFormGroup.controls['numberOfShareholders'].setValue(null);
      this.businessInfoFormGroup.get('shares').clearValidators();
      this.businessInfoFormGroup.get('shares').updateValueAndValidity();
    }
  }
  
  sharesRequired():boolean{
    if(this.navigatorLookupService.shareholderBusinessTypes.includes(this.businessType)){
      return true;
    }
    return false;
  }
  get f() {
    return this.businessInfoFormGroup.controls;
  }

  get s() {
    return this.f.shares as FormArray;
  }
  get d() {
    return this.f.devices as FormArray;
  }
  onChangeShareHolder() {
    const numberOfShareHolders = this.businessInfoFormGroup.get('numberOfShareholders').value;
    if (this.s.length < numberOfShareHolders) {
      for (let i = this.s.length; i < numberOfShareHolders; i++) {
        this.s.push(this.formBuilder.group({
          share: new FormControl('', [Validators.required, Validators.pattern('^[0-9.]*$'), Validators.min(1), Validators.max(100)]),
          shareHolderName: new FormControl('', [Validators.required]),
          shareHolderID: new FormControl('', [Validators.required, Validators.pattern('(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))')])
        }));
      }
    } else {
      for (let i = this.s.length; i >= numberOfShareHolders; i--) {
        this.s.removeAt(i);
      }
    }
  }
  onChangeNumVasDevices() {
    const numberOfVasDevices = this.businessInfoFormGroup.get('numVASDevices').value;
    if (this.d.length < numberOfVasDevices) {
      for (let i = this.d.length; i < numberOfVasDevices; i++) {
        this.d.push(this.formBuilder.group({
          terminal: new FormControl('', [Validators.required]),
        }));
      }
    } else {
      for (let i = this.d.length; i >= numberOfVasDevices; i--) {
        this.d.removeAt(i);
      }
    }
  }
  onChangeBusinessTypes() {
    this.passBusinessType.emit(this.businessInfoFormGroup.get('businessType').value);
    if (this.businessType === 'PARTNERSHIP') {
      this.ownerNumbers[0].disabled = true;
      this.businessInfoFormGroup.patchValue({numberOfOwners: ''});
    } else {
      this.ownerNumbers[0].disabled = false;
    }
    if (this.businessType === 'SOLE_PROPRIETOR') {
      this.businessInfoFormGroup.patchValue({numberOfOwners: 1});
      this.onChangeOwners();
    }
  }

  onChangeOwners() {
    this.passNumberOfOwners.emit(this.businessInfoFormGroup.get('numberOfOwners').value);
  }

  get businessType(): any {
    return this.businessInfoFormGroup.get('businessType').value;
  }

  get businessTradingName(): any {
    return this.businessInfoFormGroup.get('businessTradingName').value;
  }

  get businessRegName(): any {
    return this.businessInfoFormGroup.get('businessRegName').value;
  }

  get businessRegNumber(): any {
    return this.businessInfoFormGroup.get('businessRegNumber').value;
  }

  get businessVATNumber(): any {
    return this.businessInfoFormGroup.get('businessVATNumber').value;
  }

  get businessNature(): any {
    return this.businessInfoFormGroup.get('businessNature').value;
  }

  get merchantIndustry(): any {
    return this.businessInfoFormGroup.get('merchantIndustry').value;
  }

  get annualTurnOver(): any {
    return this.businessInfoFormGroup.get('annualTurnOver').value;
  }

  get numberOfOwners(): any {
    return this.businessInfoFormGroup.get('numberOfOwners').value;
  }

  get numberOfShareholders(): any {
    return this.businessInfoFormGroup.get('numberOfShareholders').value;
  }

  get buildingName(): any {
    return this.businessInfoFormGroup.get('buildingName').value;
  }

  get street(): any {
    return this.businessInfoFormGroup.get('street').value;
  }

  get suburb(): any {
    return this.businessInfoFormGroup.get('suburb').value;
  }

  get city(): any {
    return this.businessInfoFormGroup.get('city').value;
  }

  get province(): any {
    return this.businessInfoFormGroup.get('province').value;
  }

  get postalCode(): any {
    return this.businessInfoFormGroup.get('postalCode').value;
  }

  get businessTelephoneNumber(): any {
    return this.businessInfoFormGroup.get('businessTelephoneNumber').value;
  }

  get businessEmailAddress(): any {
    return this.businessInfoFormGroup.get('businessEmailAddress').value;
  }

  get primaryContactPerson(): any {
    return this.businessInfoFormGroup.get('primaryContactPerson').value;
  }

  get primaryContactNumber(): any {
    return this.businessInfoFormGroup.get('primaryContactNumber').value;
  }

  get tradingHours(): any {
    return this.businessInfoFormGroup.get('tradingHours').value;
  }
  setMerchantIndustry(industry){
    this.businessInfoFormGroup.patchValue({merchantIndustry:industry.code+' '+industry.name});
  }
  clearVASValidator(){
      this.businessInfoFormGroup.get('numberOfShareholders').clearValidators();
      this.businessInfoFormGroup.get('numberOfShareholders').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegName').clearValidators();
      this.businessInfoFormGroup.get('businessRegName').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessType').clearValidators();
      this.businessInfoFormGroup.get('businessType').updateValueAndValidity();
      this.businessInfoFormGroup.get('annualTurnOver').clearValidators();
      this.businessInfoFormGroup.get('annualTurnOver').updateValueAndValidity();

      this.businessInfoFormGroup.get('merchantNumber').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('merchantNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('legalEntityName').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('legalEntityName').updateValueAndValidity();


  }
  setBusinessTypeValidator(value){
    this.businessInfoFormGroup.get('merchantNumber').clearValidators();
      this.businessInfoFormGroup.get('merchantNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('legalEntityName').clearValidators();
      this.businessInfoFormGroup.get('legalEntityName').updateValueAndValidity();
    if(value==='SOLE_PROPRIETOR'){
      this.businessInfoFormGroup.get('numberOfOwners').clearValidators();
      this.businessInfoFormGroup.get('numberOfOwners').updateValueAndValidity();
      this.businessInfoFormGroup.get('numberOfShareholders').clearValidators();
      this.businessInfoFormGroup.get('numberOfShareholders').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegName').clearValidators();
      this.businessInfoFormGroup.get('businessRegName').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegNumber').clearValidators();
      this.businessInfoFormGroup.get('businessRegNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessVATNumber').clearValidators();
      this.businessInfoFormGroup.get('businessVATNumber').updateValueAndValidity();
    }
    else if(value==='PARTNERSHIP'){
      this.businessInfoFormGroup.get('numberOfOwners').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('numberOfOwners').updateValueAndValidity();
      this.businessInfoFormGroup.get('numberOfShareholders').clearValidators();
      this.businessInfoFormGroup.get('numberOfShareholders').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegName').clearValidators();
      this.businessInfoFormGroup.get('businessRegName').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegNumber').clearValidators();
      this.businessInfoFormGroup.get('businessRegNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessVATNumber').clearValidators();
      this.businessInfoFormGroup.get('businessVATNumber').updateValueAndValidity();
    }
    else if(value="CLOSE_CORPORATION"){
      this.businessInfoFormGroup.get('numberOfOwners').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('numberOfOwners').updateValueAndValidity();
      this.businessInfoFormGroup.get('numberOfShareholders').clearValidators();
      this.businessInfoFormGroup.get('numberOfShareholders').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegName').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('businessRegName').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegNumber').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9/-]*$')]);
      this.businessInfoFormGroup.get('businessRegNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessVATNumber').setValidators([Validators.pattern('^[0-9]*$')]);
      this.businessInfoFormGroup.get('businessVATNumber').updateValueAndValidity();
    }
    else{
      this.businessInfoFormGroup.get('numberOfShareholders').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('numberOfShareholders').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegName').setValidators([Validators.required]);
      this.businessInfoFormGroup.get('businessRegName').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessRegNumber').setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]);
      this.businessInfoFormGroup.get('businessRegNumber').updateValueAndValidity();
      this.businessInfoFormGroup.get('businessVATNumber').setValidators([Validators.pattern('^[0-9]*$')]);
      this.businessInfoFormGroup.get('businessVATNumber').updateValueAndValidity();
    }
  }
  returnBusinessType(){
    if(this.businessType === 'PARTNERSHIP' || this.businessType === ''){
      return 'How many owners in the business?';
    }
    else if(this.businessType === 'PRIVATE_COMPANY' || this.businessType === 'CLOSE_CORPORATION' || this.businessType === 'PUBLIC_COMPANY'){
      return 'How many directors in the business?';
    }
    else if(this.businessType === 'TRUST'){
       return 'How many trustees in the trust?';
    }
    else if(this.businessType === 'NGO_NPO'){
      return 'How many executive members in the NGO/NPO?';
    }
  }
}
